import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import {
    offers,
    dashboards,
    sidebarReducer,
    themeReducer,
    usersReducer,
    payments,
    stat,
    convstat,
    reports
} from '../../redux/reducers/index';
import thunk from 'redux-thunk';

const { logger } = require(`redux-logger`)
let middleWares = [thunk]
if (process.env.NODE_ENV !== 'production') {
    middleWares.push(logger)
}

const reducer = combineReducers({
    form: reduxFormReducer,
    theme: themeReducer,
    sidebar: sidebarReducer,
    users: usersReducer,
    offers: offers,
    dashboards: dashboards,
    stat: stat,
    convstat: convstat,
    payments: payments,
    reports: reports,
});

const rootReducer = (state, action) => {
    if (action.type === 'SIGN_OUT') {
        state = undefined
    }
    return reducer(state, action)
}

export function configureStore(initialState) {
    const store = createStore(
        reducer,
        compose(
            applyMiddleware(...middleWares),

        )
    )

    return store;
}

export default configureStore();
