import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import store from '../../App/store'
import TopbarMenuLink from './TopbarMenuLink';
import {signOutAction} from '../../../redux/actions/usersActions'
import {Link} from "react-router-dom";

export default class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
    this.signout = this.signout.bind(this)
  }

  signout() {
    store.dispatch(signOutAction)
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar profile-mail" onClick={this.toggle}>
          <p className="topbar__avatar-name">{store.getState().users.currentUser.email}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="My Profile" icon="user" path="/profile" />
            {/*<TopbarMenuLink title="Sign out" icon="door" path="/" onClick={this.signout} />*/}
            <a className="topbar__link" onClick={this.signout}>
              <span className={`topbar__link-icon lnr lnr-exit`} />
              <p className="topbar__link-title">Sign out</p>
            </a>
            {/*<TopbarMenuLink title="Tasks" icon="list" path="/default_pages/calendar" />*/}
            {/*<TopbarMenuLink title="Inbox" icon="inbox" path="/mail" />*/}
            {/*<div className="topbar__menu-divider" />*/}
            {/*<TopbarMenuLink title="Account Settings" icon="cog" path="/account/profile" />*/}
            {/*<TopbarMenuLink title="Lock Screen" icon="lock" path="/lock_screen" />*/}
            {/*<TopbarMenuLink title="Log Out" icon="exit" path="/log_in" />*/}
          </div>
        </Collapse>
      </div>
    );
  }
}
