import moment from 'moment'
import { LOAD_REPORT, ADD_REPORT } from './../actions/reportActions'

const DEFAULT_GROUPING = 'date';
const DEFAULT_FILTERS = {
  date_from: moment().subtract(29, 'days'),
  date_to: moment(),
  group: [DEFAULT_GROUPING],
};

const initialState = {
  offers: [],
  offers_filters: { ...DEFAULT_FILTERS },
  offers_loading: false,
  offers_loaded: false,
};

export const reports = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_REPORT:
      return {
        ...state,
        [action.scope]: [],
        [`${action.scope}_filters`]: action.filters,
        [`${action.scope}_loading`]: true,
        [`${action.scope}_loaded`]: false,
      };

    case ADD_REPORT:
      return {
        ...state,
        [action.scope]: action.data.items,
        [`${action.scope}_total`]: action.data.total,
        [`${action.scope}_loading`]: false,
        [`${action.scope}_loaded`]: false,
      };

    default:
      return state
  }
};
