import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { scaleTime } from 'd3-scale'
import { timeDay } from 'd3-time'
import { Col } from 'reactstrap';
import classNames from 'classnames'

import ChartReportComponent from './ChartReportComponent'
import { REPORTS_CHART_ITEMS } from '../../../../constants/reports'

class ReportsCharts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lineCharts: [],
      chartData: [],
      isChartVisible: props.isChartVisible,
      chartsState: {
        stylesPadding: 'pb-0',
      },
    }
  }

  componentDidMount() {
    this.props.onRef(this)
    const { chartItems, reportData, optionsFilters } = this.props
    const lineCharts = this.prepareLineChartsProperties(chartItems)
    const chartData = this.prepareChartsData(lineCharts, reportData, optionsFilters)
    this.setState({
      lineCharts: lineCharts,
      chartData: chartData,
    })
  }

  showChart = () => {
    const { isChartVisible } = this.state
    const chartsState = !isChartVisible ?
      { chart: 'charts-expand', stylesPadding: 'pb-20' } : { chart: 'charts-collapse', stylesPadding: 'pb-0' }
    this.setState({
      isChartVisible: !isChartVisible,
      chartsState: chartsState,
    })
  }

  prepareLineChartsProperties = (chartItems = []) => {
    let lineCharts = []
    chartItems.map((chartItem, i) => {
      let foundItem = REPORTS_CHART_ITEMS.find((item) => item.value === chartItem)
      lineCharts.push(foundItem)
    })

    return lineCharts
  }

  prepareChartsData = (lineCharts, reportData, optionsFilters) => {
    const date_from = { ...optionsFilters }.date_from
    const date_to = { ...optionsFilters }.date_to
    const xTicks = (date_from && date_to) ? scaleTime().domain([date_from, date_to]).ticks(timeDay.every(1)) : []
    let chartData = reportData.slice(0)
    let emptyChartData = {}

    lineCharts.map((el, i) => emptyChartData[el.value] = 0)

    xTicks.forEach(tick => {
      const tickFormatted = moment(tick).format('YYYY-MM-DD')
      if (chartData.findIndex(d => d.date === tickFormatted) < 0) {
        chartData.push({
          date: tickFormatted,
          ...emptyChartData,
        })
      }
    })
    chartData.sort((a, b) => moment(a.date) - moment(b.date))

    return chartData
  }

  render() {
    const { chartItems, reportData, optionsFilters } = this.props
    const { isChartVisible, chartsState } = this.state
    const lineCharts = this.prepareLineChartsProperties(chartItems)
    const chartData = this.prepareChartsData(lineCharts, reportData, optionsFilters)
    return (
      <Fragment>
        <div
          className={classNames('panel-body pl-0 pt-0 pr-0 mt--30', chartsState.stylesPadding || '')}
        >
          <Col sm={12} className="pl-0 pr-0">
            <div
              className={classNames('charts', chartsState.chart || '')}
            >
              {isChartVisible &&
              <ChartReportComponent
                lineCharts={lineCharts}
                chartData={chartData}
              />
              }
            </div>
          </Col>
        </div>
      </Fragment>
    )
  }
}

ReportsCharts.propTypes = {
  chartItems: PropTypes.arrayOf(PropTypes.string),
  scope: PropTypes.string,
  chartData: PropTypes.array,
  reportData: PropTypes.array,
  optionsFilters: PropTypes.object,
  isChartVisible: PropTypes.bool,
}

ReportsCharts.defaultProps = {
  isChartVisible: false,
  reportData: [],
  optionsFilters: {},
  // chartItems: ['clicks', 'conversions', 'cost', 'unique_clicks'],
  chartItems: ['clicks', 'conversions'],
}

export default ReportsCharts
