import React, { Component } from 'react';
import { Col } from 'reactstrap';
import classnames from 'classnames';
import store from '../../../App/store'

import { DASHBOARD_BY_DAY, DASHBOARD_BY_OFFER } from '../../../../constants'

class ToggleButtonGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeButton: DASHBOARD_BY_DAY,
    };
  }

  componentDidMount() {
  }

  toggle = (btn) => {
    if (this.state.activeTab !== btn) {
      this.setState({
        activeButton: btn,
      });
    }
  };

  toggleButtonClickHandler = (event) => {
    event.preventDefault();
    const { toggleAction } = this.props;
    const { target } = event;
    this.toggle(target.name);
    toggleAction(target.name)
  };

  render() {
    const users = store.getState().users || {};
    const hideCampaigns = JSON.parse(localStorage.getItem('settings')) && JSON.parse(localStorage.getItem('settings')).domain_id == '624de8e02744a700012a5601' ? true : false
    const { activeButton } = this.state;
    return (
      <Col md={2} lg={2} className="no-padding-left">
        <div role="toolbar" className="btn-toolbar">
          <div role="group" className=" btn-group">
            <button
              id="button_1"
              name="by_date"
              className={"btn btn-primary " + classnames({active: activeButton === DASHBOARD_BY_DAY})}
              style={{
                height: 42,
                lineHeight: '10px'
              }}
              onClick={this.toggleButtonClickHandler}
            >
              By Day
            </button>
            {!hideCampaigns &&
            <button
                id="button_2"
                name="by_offer"
                className={"btn btn-primary " + classnames({active: activeButton === DASHBOARD_BY_OFFER})}
                style={{
                  height: 42,
                  lineHeight: '10px'
                }}
                onClick={this.toggleButtonClickHandler}
            >
              By Campaign
            </button>
            }
          </div>
        </div>
      </Col>
    )
  }
}

export default ToggleButtonGroup
