import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Select from 'react-select'
import { Col } from 'reactstrap';
import _ from 'lodash'
import classNames from 'classnames'

import { DASHBOARD_METRICS_LIST } from '../../../../constants/dashboard'


class Metrics extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.getListMetricsOptions = this.getListMetricsOptions.bind(this)
    this.getStylesForSelect = this.getStylesForSelect.bind(this)
  }

  getListMetricsOptions(data) {
      return data.map(option => ({
          value: option.value,
          label: option.label,
          isDisabled: option.disabled,
          ...option,
      }))
  }

  getStylesForSelect() {
    const { metrics } = this.props
    let styles = {}
    DASHBOARD_METRICS_LIST.map((metric, i) => {
      styles[metric] = {
        border: metrics[metric].lineChartProperties.stroke ? `1px solid ${metrics[metric].lineChartProperties.stroke}` : '',
      }
    })
    return styles
  }

  render() {
    const {
      handleChangeMetrics_A,
      handleChangeMetrics_B,
      handleChangeMetrics_C,
      handleChangeMetrics_D,
      stat,
    } = this.props
    const { metrics_A, metrics_B, metrics_C, metrics_D } = this.props.metrics
    const styles = this.getStylesForSelect()
    const items = stat || []
    const currencyOptions = {
      usd: '$',
      eur: '€',
    }
    let currencySign
    if (this.props.user && this.props.user.settings && this.props.user.settings.currency) {
      currencySign = currencyOptions[this.props.user.settings.currency]
    }
    else {
      currencySign = '$'
    }
    return (
      <div className="filters form__form-group pt-0 pr-0">
        <div className="form__form-group-field  panel-body-container">
          <Col
            md={2}
            className='metricA-cell'
          >
            <div
              className='cell-container metricA-container'
              style={{ ...styles.metrics_A }}
            >
              <div
                className='row-container'
              >
                <Select
                  id='metric_A'
                  placeholder={'metric_A'}
                  options={this.getListMetricsOptions(metrics_A.options)}
                  value={metrics_A.value ? {
                    value: metrics_A.value,
                    label: metrics_A.label,
                  } : metrics_A.value}
                  onChange={handleChangeMetrics_A}
                  isClearable={false}
                  className={classNames('react-select', 'select-container', 'select-container-first')}
                  classNamePrefix="react-select"
                />
              </div>
              <hr className="mt-0 mb-0 mr-10 ml-10" />
              <div className="text-bold text-center react-select">
                {(metrics_A.index || metrics_A.index === 0) && `${metrics_A.options[metrics_A.index].currency ? currencySign : ''}${metrics_A.options[metrics_A.index].aggregator(_.sumBy,items)}`}
              </div>
            </div>
          </Col>

          <Col
            md={2}
            className='metricB-cell'
          >
            <div
              className='cell-container metricB-container'
              style={{ ...styles.metrics_B }}
            >
              <div
                className='row-container'
              >
                <Select
                  id='metric_B'
                  placeholder={'metric_B'}
                  options={this.getListMetricsOptions(metrics_B.options)}
                  value={metrics_B.value ? {
                    value: metrics_B.value,
                    label: metrics_B.label,
                  } : metrics_B.value}
                  onChange={handleChangeMetrics_B}
                  isClearable={true}
                  className={classNames('react-select', 'select-container')}
                  classNamePrefix="react-select"
                />
              </div>
              <hr className="mt-0 mb-0 mr-10 ml-10"/>
              <div className="text-bold text-center react-select">
                {(metrics_B.index || metrics_B.index === 0) && `${metrics_B.options[metrics_B.index].currency ? currencySign : ''}${metrics_B.options[metrics_B.index].aggregator(_.sumBy, items)}`}
              </div>
            </div>
          </Col>

          <Col
            md={2}
            className='metricC-cell'
          >
            <div
              className='cell-container metricC-container'
              style={{ ...styles.metrics_C }}
            >
              <div
                className='row-container'
              >
                <Select
                  id='metric_C'
                  placeholder={'metric_C'}
                  options={this.getListMetricsOptions(metrics_C.options)}
                  value={metrics_C.value ? {
                    value: metrics_C.value,
                    label: metrics_C.label,
                  } : metrics_C.value}
                  onChange={handleChangeMetrics_C}
                  isClearable={true}
                  className={classNames('react-select', 'select-container')}
                  classNamePrefix="react-select"
                />
              </div>
              <hr className="mt-0 mb-0 mr-10 ml-10"/>
              <div className="text-bold text-center react-select">
                {(metrics_C.index || metrics_C.index === 0) && `${metrics_C.options[metrics_C.index].currency ? currencySign : ''}${metrics_C.options[metrics_C.index].aggregator(_.sumBy, items)}`}
              </div>
            </div>
          </Col>

          <Col
            md={2}
            className='metricD-cell'
          >
            <div
              className='cell-container metricD-container'
              style={{ ...styles.metrics_D }}
            >
              <div
                className='row-container'
              >
                <Select
                  id='metric_D'
                  placeholder={'metric_D'}
                  options={this.getListMetricsOptions(metrics_D.options)}
                  value={metrics_D.value ? {
                    value: metrics_D.value,
                    label: metrics_D.label,
                  } : metrics_D.value}
                  onChange={handleChangeMetrics_D}
                  isClearable={true}
                  className={classNames('react-select', 'select-container')}
                  classNamePrefix="react-select"
                />
              </div>
              <hr className="mt-0 mb-0 mr-10 ml-10"/>
              <div className="text-bold text-center react-select">
                {(metrics_D.index || metrics_D.index === 0) && `${metrics_D.options[metrics_D.index].currency ? currencySign : ''}${metrics_D.options[metrics_D.index].aggregator(_.sumBy, items)}`}
              </div>
            </div>
          </Col>
        </div>
      </div>
    )
  }
}

Metrics.propTypes = {
  handleChangeMetrics_A: PropTypes.func,
  handleChangeMetrics_B: PropTypes.func,
  handleChangeMetrics_C: PropTypes.func,
  handleChangeMetrics_D: PropTypes.func,
}

Metrics.defaultProps = {
  user: {},
}

export default Metrics
