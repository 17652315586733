import axios from 'axios'
import store from '../containers/App/store'
import { signOutAction } from "../redux/actions/usersActions";
import { UNAUTHORIZED_USER_STATUS } from "../constants";

let apihost = '/api';

function makeUrl(uri, options={}, host=apihost) {
    return `${host}/${uri}?api_key=${store.getState().users.currentUser.apikey}`
}

export const signin = credentials => {
    return axios.post(`${apihost}/auth`, credentials, { headers: {'Content-Type': 'multipart/form-data' }})
}

export const loadSettings = (params, callback) => {
    return axios.get('/settings').then((items) => {
        callback(items.data)
    })
    .catch(error => {
        catchErrorToSignOut(error)
        return Promise.reject(error)
    })
}

export const signup = credentials => {
    return axios.post(`${apihost}/publishers`, JSON.stringify(credentials), { headers: {'Content-Type': 'multipart/form-data' }})
}

export const resetPassword = credentials => {
    return axios.post(`${apihost}/reset_password`, credentials, { headers: {'Content-Type': 'multipart/form-data' }})
}

export const updateUser = data => {
    return axios.put(makeUrl('profile'), data)
    .catch(error => {
        catchErrorToSignOut(error)
        return Promise.reject(error)
    })
}

export const getUserData = data => {
    return axios.get(makeUrl('me'), data)
    .catch(error => {
        catchErrorToSignOut(error)
        return Promise.reject(error)
    })
}

export const getUnregisteredUserData = data => {
    return axios.get(`${apihost}/me?api_key=${data}`)
}

export const loadOffers = (params, callback) => {
    return axios.get(makeUrl('offers'), {params:params}  ).then((items) => {
        callback(items.data);
        return items.data
    })
    .catch(error => {
        catchErrorToSignOut(error)
        return Promise.reject(error)
    })
}

// export const loadReport = (params={}, callback) => {
//     return axios.get(makeUrl('stat'), { params: params }  ).then((items) => {
//         callback(items.data)
//     })
//     .catch(error => {
//         catchErrorToSignOut(error)
//         return Promise.reject(error)
//     })
// };

export const loadReport = (params={}, callback) => {
    return axios.get(makeUrl('report'), { params: params }  ).then((items) => {
        callback(items.data)
        return items.data
    })
    .catch(error => {
        catchErrorToSignOut(error)
        return Promise.reject(error)
    })
};

export const loadPayments = (params, callback) => {
    return axios.get(makeUrl('payments'), {params:params}  ).then((items) => {
        callback(items.data)
    })
    .catch(error => {
        catchErrorToSignOut(error)
        return Promise.reject(error)
    })
}

export const loadStat = (params, callback) => {
    return axios.get(makeUrl('stat'), {params:params}  ).then((items) => {
        callback(items.data)
    })
    .catch(error => {
        catchErrorToSignOut(error)
        return Promise.reject(error)
    })
}

export const loadConversionsStat = (params, callback) => {
    return axios.get(makeUrl('conversions_stat'), {params:params}  ).then((items) => {
        callback(items.data)
    })
    .catch(error => {
        catchErrorToSignOut(error)
        return Promise.reject(error)
    })
}

export const catchErrorToSignOut = (error) => {
    if (error.response) {
        if (error.response.status === UNAUTHORIZED_USER_STATUS) {
            store.dispatch(signOutAction)
        }
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
    } else if (error.request) {
        // console.log(error.request);
    } else {
        // console.log('Error', error.message);
    }
}

export const signout = () => {console.log('sign out')}
