import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import {connect} from "react-redux";
import classnames from 'classnames';
import store from "../../App/store";

class TopbarWithNavigation extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, user, settings, users } = this.props;
    const hideCampaigns = JSON.parse(localStorage.getItem('settings')) && JSON.parse(localStorage.getItem('settings')).domain_id == '624de8e02744a700012a5601' ? true : false
    const currentPath = this.props.location.pathname
    const activeStyles = {
      trafficDashboard: currentPath === '/dashboard' ? 'active-link' : '',
      offers: currentPath === '/offers' ? 'active-link' : '',
      payments: currentPath === '/payments' ? 'active-link' : '',
      postback: currentPath === '/postback' ? 'active-link' : '',
    }
    let manager_email = user && user && user.manager_email ? user.manager_email : ''
    if (!manager_email) manager_email = settings && settings.manager_email ? settings.manager_email : ''

    return (
      <div className="topbar topbar--navigation">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} />
            <Link className={this.props.settings && this.props.settings.logo_url ? "topbar__logo__empty" : "topbar__logo"} to="/dashboard" >
              {this.props.settings && this.props.settings.logo_url ?
                  <img src={this.props.settings.logo_url} className="account__rtk-logo-header " /> :
                  null
              }
            </Link>
            <Link className={classnames("topbar__nav-link-custom", activeStyles.trafficDashboard)} to="/dashboard">Dashboard</Link>
            {!hideCampaigns &&
              <Link className={classnames("topbar__nav-link-custom", activeStyles.offers)} to="/offers">Campaigns</Link>
            }
            {manager_email &&
            <p className="topbar__nav-link manager-email">{`Manager: ${manager_email}`}</p>
            }
          </div>
          {/*<TopbarNav />*/}
          <div className="topbar__right">
            {user && settings && settings.show_payouts &&
              <span className="topbar__nav-link">Balance: {this.props.user.balance.toFixed(2)}$</span>
            }
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.users.currentUser,
  settings:  state.users.settings
})

export default connect(
    mapStateToProps,
)(TopbarWithNavigation)
