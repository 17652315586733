import React from "react";
import numeral from 'numeral'
import {
  renderRevenueTypeTitle,
  renderConvTypeTitle,
  renderConvTypeRateTitle,
  renderConvTypeCpaTitle,
} from '../utils';
import { renderDifConvTypeAov } from '../utils/columns';
import { CONVTYPE_COUNT } from '../constants/columns';

export const FINANCIAL_HEADS_OFFERS = (usersSettings) => ([
  {
    key: 'impressions',
    name: 'Impressions',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  {
    key: 'impressions_visible',
    name: 'Visible Impressions',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  {
    key: 'clicks',
    name: 'Clicks',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  {
    key: 'unique_clicks',
    name: 'Unique clicks',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  {
    key: 'ctr',
    name: 'CTR',
    formatter: ({ value }) => numeral(value).format('0.00%'),
    resizable: true,
    sortable: false,
  },
  {
    key: 'lp_views',
    name: 'LP Views',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  {
    key: 'prelp_views',
    name: 'PreLP Views',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  {
    key: 'lp_clicks',
    name: 'LP clicks',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  {
    key: 'prelp_clicks',
    name: 'Pre LP clicks',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  {
    key: 'prelp_click_ctr',
    name: 'Pre-LP click CTR',
    formatter: ({ row }) => numeral(row.clicks && row.clicks !== 0 ? row.prelp_clicks / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false,
  },
  {
    key: 'lp_ctr',
    name: 'LP CTR',
    formatter: ({ value }) => numeral(value).format('0.00%'),
    resizable: true,
    sortable: false,
  },
  {
    key: 'lp_views_cr',
    name: 'LP Views CR',
    formatter: ({ row }) => numeral(row.lp_views && row.lp_views !== 0 ? row.conversions / row.lp_views : 0).format('0.00%'),
    resizable: true,
    sortable: false,
  },
  {
    key: 'lp_clicks_cr',
    name: 'LP Clicks CR',
    formatter: ({ row }) => numeral(row.lp_clicks && row.lp_clicks !== 0 ? row.conversions / row.lp_clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false,
  },
  {
    key: 'lp_click_ctr',
    name: 'LP Click CTR',
    formatter: ({ row }) => numeral(row.clicks && row.clicks !== 0 ? row.lp_clicks / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false,
  },
  {
    key: 'conversions',
    name: renderConvTypeTitle('conv_default_type','conv_', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'total_conversions',
    name: 'Total Actions',
    width: 150,
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'total_conversions_cr',
    name: 'Total Actions CR',
    width: 150,
    formatter: ({ row }) => numeral(row.total_conversions && row.clicks !== 0 ? row.total_conversions / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'cr',
    name: 'CR',
    formatter: ({ value }) => numeral(value).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'approved',
    name: 'Approved',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'approved_rate',
    name: 'Approved rate',
    formatter: ({ row }) => numeral(row.approved ? row.approved / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'pending',
    name: 'Pending',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'pending_rate',
    name: 'Pending rate',
    formatter: ({ row }) => numeral(row.pending ? row.pending / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'declined',
    name: 'Declined',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'declined_rate',
    name: 'Declined rate',
    formatter: ({ row }) => numeral(row.declined ? row.declined / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'other',
    name: 'Other',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'other_rate',
    name: 'Other rate',
    formatter: ({ row }) => numeral(row.other ? row.other / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'transactions',
    name: 'Transactions',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'revenue',
    name: renderRevenueTypeTitle('revenue_default_type','revenue_', usersSettings),
    width: 180,
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    resizable: true,
    sortable: false
  },
  {
    key: 'total_revenue',
    name: 'Total Revenue',
    width: 150,
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    resizable: true,
    sortable: false
  },
  {
    key: 'cost',
    name: 'Cost',
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    resizable: true,
    sortable: false
  },
  // {
  //   key: 'pubrevenue',
  //   name: 'Comission',
  //   formatter: ({ value }) => numeral(value).format('$0.00[00]'),
  //   resizable: true,
  //   sortable: false
  // },
  {
    key: 'profit',
    name: 'Profit',
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    resizable: true,
    sortable: false
  },
  {
    key: 'roi',
    name: 'ROI',
    formatter: ({ value }) => numeral(value).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'cpa',
    name: 'CPA',
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    resizable: true,
    sortable: false
  },
  {
    key: 'cpc',
    name: 'CPC',
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    resizable: true,
    sortable: false
  },
  {
    key: 'cpt',
    name: 'CPT',
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    resizable: true,
    sortable: false
  },
  {
    key: 'epc',
    name: 'EPC',
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    resizable: true,
    sortable: false,
  },
  {
    key: 'convtype1',
    name: renderConvTypeTitle('convtype1','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype2',
    name: renderConvTypeTitle('convtype2','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype3',
    name: renderConvTypeTitle('convtype3','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype4',
    name: renderConvTypeTitle('convtype4','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype5',
    name: renderConvTypeTitle('convtype5','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype6',
    name: renderConvTypeTitle('convtype6','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype7',
    name: renderConvTypeTitle('convtype7','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype8',
    name: renderConvTypeTitle('convtype8','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype9',
    name: renderConvTypeTitle('convtype9','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype10',
    name: renderConvTypeTitle('convtype10','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype11',
    name: renderConvTypeTitle('convtype11','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype12',
    name: renderConvTypeTitle('convtype12','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype13',
    name: renderConvTypeTitle('convtype13','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype14',
    name: renderConvTypeTitle('convtype14','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype15',
    name: renderConvTypeTitle('convtype15','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype16',
    name: renderConvTypeTitle('convtype16','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype17',
    name: renderConvTypeTitle('convtype17','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype18',
    name: renderConvTypeTitle('convtype18','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype19',
    name: renderConvTypeTitle('convtype19','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convtype20',
    name: renderConvTypeTitle('convtype20','conv', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype1',
    name: renderConvTypeRateTitle('convtype1','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype1 && row.clicks !== 0 ? row.convtype1 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype2',
    name: renderConvTypeRateTitle('convtype2','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype2 && row.clicks !== 0 ? row.convtype2 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype3',
    name: renderConvTypeRateTitle('convtype3','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype3 && row.clicks !== 0 ? row.convtype3 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype4',
    name: renderConvTypeRateTitle('convtype4','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype4 && row.clicks !== 0 ? row.convtype4 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype5',
    name: renderConvTypeRateTitle('convtype5','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype5 && row.clicks !== 0 ? row.convtype5 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype6',
    name: renderConvTypeRateTitle('convtype6','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype6 && row.clicks !== 0 ? row.convtype6 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype7',
    name: renderConvTypeRateTitle('convtype7','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype7 && row.clicks !== 0 ? row.convtype7 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype8',
    name: renderConvTypeRateTitle('convtype8','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype8 && row.clicks !== 0 ? row.convtype8 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype9',
    name: renderConvTypeRateTitle('convtype9','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype9 && row.clicks !== 0 ? row.convtype9 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype10',
    name: renderConvTypeRateTitle('convtype10','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype10 && row.clicks !== 0 ? row.convtype10 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype11',
    name: renderConvTypeRateTitle('convtype11','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype11 && row.clicks !== 0 ? row.convtype11 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype12',
    name: renderConvTypeRateTitle('convtype12','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype12 && row.clicks !== 0 ? row.convtype12 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype13',
    name: renderConvTypeRateTitle('convtype13','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype13 && row.clicks !== 0 ? row.convtype13 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype14',
    name: renderConvTypeRateTitle('convtype14','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype14 && row.clicks !== 0 ? row.convtype14 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype15',
    name: renderConvTypeRateTitle('convtype15','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype15 && row.clicks !== 0 ? row.convtype15 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype16',
    name: renderConvTypeRateTitle('convtype16','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype16 && row.clicks !== 0 ? row.convtype16 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype17',
    name: renderConvTypeRateTitle('convtype17','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype17 && row.clicks !== 0 ? row.convtype17 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype18',
    name: renderConvTypeRateTitle('convtype18','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype18 && row.clicks !== 0 ? row.convtype18 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype19',
    name: renderConvTypeRateTitle('convtype19','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype19 && row.clicks !== 0 ? row.convtype19 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'convratetype20',
    name: renderConvTypeRateTitle('convtype20','conv', usersSettings),
    formatter: ({ row }) => numeral(row.convtype20 && row.clicks !== 0 ? row.convtype20 / row.clicks : 0).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype1',
    name: renderRevenueTypeTitle('revenuetype1','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype2',
    name: renderRevenueTypeTitle('revenuetype2','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype3',
    name: renderRevenueTypeTitle('revenuetype3','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype4',
    name: renderRevenueTypeTitle('revenuetype4','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype5',
    name: renderRevenueTypeTitle('revenuetype5','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype6',
    name: renderRevenueTypeTitle('revenuetype6','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype7',
    name: renderRevenueTypeTitle('revenuetype7','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype8',
    name: renderRevenueTypeTitle('revenuetype8','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype9',
    name: renderRevenueTypeTitle('revenuetype9','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype10',
    name: renderRevenueTypeTitle('revenuetype10','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype11',
    name: renderRevenueTypeTitle('revenuetype11','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype12',
    name: renderRevenueTypeTitle('revenuetype12','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype13',
    name: renderRevenueTypeTitle('revenuetype13','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype14',
    name: renderRevenueTypeTitle('revenuetype14','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype15',
    name: renderRevenueTypeTitle('revenuetype15','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype16',
    name: renderRevenueTypeTitle('revenuetype16','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype17',
    name: renderRevenueTypeTitle('revenuetype17','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype18',
    name: renderRevenueTypeTitle('revenuetype18','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype19',
    name: renderRevenueTypeTitle('revenuetype19','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'revenuetype20',
    name: renderRevenueTypeTitle('revenuetype20','revenue', usersSettings),
    formatter: ({ value }) => numeral(value).format('$0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'convcpatype1',
    name: renderConvTypeCpaTitle('convtype1','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype1 !== 0 ? row.cost / row.convtype1 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'convcpatype2',
    name: renderConvTypeCpaTitle('convtype2','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype2 !== 0 ? row.cost / row.convtype2 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'convcpatype3',
    name: renderConvTypeCpaTitle('convtype3','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype3 !== 0 ? row.cost / row.convtype3 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'convcpatype4',
    name: renderConvTypeCpaTitle('convtype4','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype4 !== 0 ? row.cost / row.convtype4 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'convcpatype5',
    name: renderConvTypeCpaTitle('convtype5','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype5 !== 0 ? row.cost / row.convtype5 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'convcpatype6',
    name: renderConvTypeCpaTitle('convtype6','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype6 !== 0 ? row.cost / row.convtype6 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'convcpatype7',
    name: renderConvTypeCpaTitle('convtype7','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype7 !== 0 ? row.cost / row.convtype7 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  {
    key: 'convcpatype8',
    name: renderConvTypeCpaTitle('convtype8','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype8 !== 0 ? row.cost / row.convtype8 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype9',
    name: renderConvTypeCpaTitle('convtype9','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype9 !== 0 ? row.cost / row.convtype9 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype10',
    name: renderConvTypeCpaTitle('convtype10','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype10 !== 0 ? row.cost / row.convtype10 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype11',
    name: renderConvTypeCpaTitle('convtype11','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype11 !== 0 ? row.cost / row.convtype11 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype12',
    name: renderConvTypeCpaTitle('convtype12','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype12 !== 0 ? row.cost / row.convtype12 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype13',
    name: renderConvTypeCpaTitle('convtype13','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype13 !== 0 ? row.cost / row.convtype13 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype14',
    name: renderConvTypeCpaTitle('convtype14','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype14 !== 0 ? row.cost / row.convtype14 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype15',
    name: renderConvTypeCpaTitle('convtype15','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype15 !== 0 ? row.cost / row.convtype15 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype16',
    name: renderConvTypeCpaTitle('convtype16','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype16 !== 0 ? row.cost / row.convtype16 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype17',
    name: renderConvTypeCpaTitle('convtype17','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype17 !== 0 ? row.cost / row.convtype17 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype18',
    name: renderConvTypeCpaTitle('convtype18','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype18 !== 0 ? row.cost / row.convtype18 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype19',
    name: renderConvTypeCpaTitle('convtype19','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype19 !== 0 ? row.cost / row.convtype19 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },{
    key: 'convcpatype20',
    name: renderConvTypeCpaTitle('convtype20','conv', usersSettings),
    formatter: ({ row }) => numeral(row.cost && row.convtype20 !== 0 ? row.cost / row.convtype20 : 0).format('0.00[00]'),
    width: 180,
    resizable: true,
    sortable: false
  },
  ...renderDifConvTypeAov(usersSettings, '', CONVTYPE_COUNT),
  {
    key: 'tr',
    name: 'TR',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  //TODO Old columns
  //
  // {
  //   key: 'approved',
  //   name: 'Approved',
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'clicks',
  //   name: 'Clicks',
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'cost',
  //   name: 'Cost',
  //   formatter: ({ value }) => numeral(value).format('$0.00[00]'),
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'cpa',
  //   name: 'CPA',
  //   formatter: ({ value }) => numeral(value).format('$0.00[00]'),
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'cpc',
  //   name: 'CPC',
  //   formatter: ({ value }) => numeral(value).format('$0.00[00]'),
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'epc',
  //   name: 'EPC',
  //   formatter: ({ value }) => numeral(value).format('$0.00[00]'),
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'revenue',
  //   name: 'Revenue',
  //   formatter: ({ value }) => numeral(value).format('$0.00[00]'),
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'roi',
  //   name: 'ROI',
  //   formatter: ({ value }) => numeral(value).format('0.00%'),
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'cpt',
  //   name: 'CPT',
  //   formatter: ({ value }) => numeral(value).format('$0.00[00]'),
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'conversions',
  //   name: 'Conversions',
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'cr',
  //   name: 'CR',
  //   formatter: ({ value }) => numeral(value).format('0.00%'),
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'declined',
  //   name: 'Declined',
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'lp_clicks',
  //   name: 'LP clicks',
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'pending',
  //   name: 'Pending',
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'tr',
  //   name: 'TR',
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'transactions',
  //   name: 'Transactions',
  //   sortable: false,
  // },
  // {
  //   key: 'other',
  //   name: 'Other',
  //   resizable: true,
  //   sortable: false,
  // },
  // {
  //   key: 'unique_clicks',
  //   name: 'Unique clicks',
  //   resizable: true,
  //   sortable: false,
  // }
])

export const NON_FINANCIAL_HEADS_OFFERS = (usersSettings) => ([
  {
    key: 'approved',
    name: 'Approved',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'clicks',
    name: 'Clicks',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  {
    key: 'conversions',
    name: renderConvTypeTitle('conv_default_type','conv_', usersSettings),
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'cr',
    name: 'CR',
    formatter: ({ value }) => numeral(value).format('0.00%'),
    resizable: true,
    sortable: false
  },
  {
    key: 'declined',
    name: 'Declined',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'lp_clicks',
    name: 'LP clicks',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  {
    key: 'pending',
    name: 'Pending',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'tr',
    name: 'TR',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
  {
    key: 'transactions',
    name: 'Transactions',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'other',
    name: 'Other',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false
  },
  {
    key: 'unique_clicks',
    name: 'Unique clicks',
    formatter: ({ value }) => (value || 0),
    resizable: true,
    sortable: false,
  },
])

export const DEFAULT_HEAD_COLUMN = {
  key: 'offer',
  name: 'Campaign',
  resizable: true,
  sortable: false,
}
