const SUBS_COUNT = 20;
const SUBS = [...Array(SUBS_COUNT)].map((sub, index) => "sub" + (index + 1));
const RT_SUB_ROLES = [
  "rt_source",
  "rt_medium",
  "rt_campaign",
  "rt_adgroup",
  "rt_ad",
  "rt_placement",
  "rt_keyword",
  "deeplink",
  "rt_placement_hashed",
];
const SUBS_ROLES = [...RT_SUB_ROLES, ...SUBS]
const BASE_FANCY_GROUP_OPTIONS = [
  "landing",
  "campaign",
  { key: "Date", subitems: ["date", "month"] },
  { key: "Day parting", subitems: ["hour_of_day", "day_of_week"] },
  {
    key: "Connection",
    subitems: [
      "connection_type",
      { key: "isp", label: "ISP" },
      { key: "ip", label: "IP list" },
      { key: "proxy_type", label: "Proxy type" },
    ],
  },
  {
    key: "Device",
    subitems: [
      { key: "device", label: "Device type" },
      "device_brand",
      "device_model",
    ],
  },
  {
    key: "OS",
    subitems: [
      { key: "os", label: "OS type" },
      { key: "os_fullname", label: "OS version" },
    ],
  },
  {
    key: "Browser",
    subitems: [
      { key: "browser", label: "Browser type" },
      { key: "browser_fullname", label: "Browser version" },
    ],
  },
  { key: "Geo", subitems: ["country", "region", "city"] },
  "language",
  { key: "ua", label: "UserAgent" },
  { key: "Referrer", subitems: ["referrer", "referrer_domain"] },
  { key: "Subs", subitems: [...SUBS_ROLES] },
];
const BASE_GROUPING_OPTIONS = [
  "landing",
  "campaign",
  "date",
  "month",
  "hour_of_day",
  "day_of_week",
  "connection_type",
  "isp",
  "ip",
  "ua",
  "device",
  "device_brand",
  "device_model",
  "os",
  "os_fullname",
  "browser",
  "browser_fullname",
  "country",
  "region",
  "city",
  "language",
  "referrer",
  "referrer_domain",
  "proxy_type",
  ...SUBS_ROLES,
];

export { SUBS_COUNT, SUBS, BASE_FANCY_GROUP_OPTIONS, BASE_GROUPING_OPTIONS, SUBS_ROLES };
