import { loadPayments   , } from '../../utils/apiAdapter'
import moment from 'moment';

export const LOAD_PAYMENTS   = 'LOAD_PAYMENTS'
export const ADD_PAYMENTS    = 'ADD_PAYMENTS'

export const loadPaymentsAction = (dispatch) => {
    dispatch({ type: LOAD_PAYMENTS })

    return loadPayments({
        date_from:     '2019-01-01',
        date_to:       moment().format('YYYY-MM-DD')
    }, (items, total) => dispatch(addPaymentsAction({ items, total })))
}

export const addPaymentsAction   = data => ({ type: ADD_PAYMENTS, data: data })