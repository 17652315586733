import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { signUpAction } from './../../../redux/actions/usersActions'
import store from '../../App/store'
import PropTypes from 'prop-types';
import validate from './validate';

const renderField = ({
                       input, placeholder, type, meta: { touched, error },
                     }) => (
    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
      <input {...input} placeholder={placeholder} type={type} />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
);

class SignUpForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: null
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value.trim() })
  }

  handleSubmit = (event) =>{
    event.preventDefault()
    let authData = new FormData()
    let stateData = this.state
    delete stateData.errors
    for ( var key in stateData ) {
      authData[key] = stateData[key]
    }
    store.dispatch(signUpAction(authData)).then((data) => {
        if (data.status === 201 && data.data.status === 0) {
            this.setState({
                errors: 'Account is unverified'
            })
            setTimeout(function () {
                this.setState({
                    errors: null
                })
                this.props.history.push('/')
            }.bind(this), 10000)
        }
        else {
            this.props.history.push('/dashboard')
        }
    })
        .catch(error => {
            if (error.response) {
                this.setState({
                    errors: error.response.data.error
                })
                setTimeout(function () {
                    this.setState({
                        errors: null
                    })
                }.bind(this), 5000)
            }
            else {
                this.setState({
                    errors: 'Invalid credentials'
                })
                setTimeout(function () {
                    this.setState({
                        errors: null
                    })
                }.bind(this), 5000)
            }
        })
  }

  render() {
    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Login</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email"
              component={renderField}
              type="text"
              placeholder="Login"
              onChange={this.handleChange.bind(this)}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component={renderField}
              type={'password'}
              placeholder="Password"
              onChange={this.handleChange.bind(this)}
            />
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            {this.state.errors &&
              <span className="form__form-group-error">{this.state.errors}</span>
            }
          </div>
        </div>
        <button disabled={this.state.email && this.state.password ? false : true} type="submit" className="btn btn-primary account__btn account__btn--small">Sign Up</button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'sign_up_form',
  validate,
})(SignUpForm);
