import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import {Field, reduxForm, change} from "redux-form";
import store from "../../App/store";
import {updateUserAction, getUserDataAction} from "../../../redux/actions/usersActions";

class Profile extends Component {

    constructor(props) {
        super(props);
        const user = store.getState().users.currentUser || {}
        this.state = {
            email: user.email || '',
            firstname: user.firstname || '',
            lastname: user.lastname || '',
            company: user.company || '',
            description: user.description || '',
        };
    }

    componentDidMount() {
        this.props.dispatch(change('profile_form', 'email', this.state.email));
        this.props.dispatch(change('profile_form', 'firstname', this.state.firstname));
        this.props.dispatch(change('profile_form', 'lastname', this.state.lastname));
        this.props.dispatch(change('profile_form', 'company', this.state.company));
        this.props.dispatch(change('profile_form', 'description', this.state.description));
        this.props.dispatch(getUserDataAction())
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value.trim() })
    }

    handleSubmit = (event) =>{
        event.preventDefault()
        const data = this.state;
        store.dispatch(updateUserAction(data))
    }

    render() {
        return (
            <Col md={6} style={{margin: 'auto'}}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">Profile</h5>
                        </div>
                        <form className="form"  onSubmit={this.handleSubmit}>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Email</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="email"
                                        component="input"
                                        type={'text'}
                                        value={this.state.email}
                                        placeholder=""
                                        disabled={true}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">First Name</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="firstname"
                                        component="input"
                                        type={'text'}
                                        value={this.state.firstname}
                                        disabled={true}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Last Name</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="lastname"
                                        component="input"
                                        type={'text'}
                                        value={this.state.lastname}
                                        disabled={true}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Company</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="company"
                                        component="input"
                                        type={'text'}
                                        value={this.state.company}
                                        disabled={true}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Description</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="description"
                                        component="textarea"
                                        style={{
                                            'resize': 'none'
                                        }}
                                        value={this.state.description}
                                        disabled={true}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

export default reduxForm({
    form: 'profile_form',
})(Profile)
