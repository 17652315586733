/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react'
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap'
import { Field, reduxForm, change } from 'redux-form'
import CopyToClipboard from 'react-copy-to-clipboard'
import ClipboardFileIcon from 'mdi-react/ClipboardFileIcon'

import noty from '../../../../utils/noty'

import { COPY_NOTIFICATION } from '../../../../constants'

const copyTrackbackLink = (url) => noty({ text: COPY_NOTIFICATION })

class OfferTrackUrlPart extends Component {
  constructor (props) {
    super(props)
    this.state = {
      url: props.resource && props.resource.url ? props.resource.url : '',
      postback_url: props.resource && props.resource.postback_url ? props.resource.postback_url : '',
    };
  }

  componentDidMount() {
    this.props.dispatch(change('trackback_urls_form', 'url', this.state.url));
    this.props.dispatch(change('trackback_urls_form', 'postback_url', this.state.postback_url));
  }

  render () {
    const { resource } = this.props;
    const url = resource && resource.url ? resource.url : ''
    const postback_url = resource && resource.postback_url ? resource.postback_url : ''

    return (
      <Card>
        <CardBody
          className="panel__body"
          style={{
            border: '0.5px solid rgb(232, 232, 232)',
            boxShadow: '0 10px 30px 1px rgba(0, 0, 0, 0.11)',
          }}
        >
          <div className="card__title">
            <h5 className="bold-text">Tracking URLS</h5>
          </div>
          <div className="form form--horizontal">
            <div className="form__form-group mb-10 no-margin-top">
              <span className="form__form-group-label">URL</span>
              <div className="form__form-group-field no-margin-left no-padding-left">
                <div
                  id="TooltipTrackField"
                  className='offer-details__field-container'
                >
                  <Field
                    name="url"
                    component="input"
                    type="text"
                    value={url}
                    placeholder={url}
                    disabled
                  />
                </div>
                <UncontrolledTooltip placement="top" target="TooltipTrackField">
                  {url}
                </UncontrolledTooltip>
                <div className="form__form-group-icon customize-icon-container">
                  <CopyToClipboard id="TooltipLinkTrack" text={url} onCopy={copyTrackbackLink}>
                    <div className="">
                      <a href="javascript:;">
                        <ClipboardFileIcon
                          className="customize-icon"
                        />
                      </a>
                    </div>
                  </CopyToClipboard>
                  <UncontrolledTooltip placement="top" target="TooltipLinkTrack">
                    {url}
                  </UncontrolledTooltip>
                </div>
              </div>
            </div>
            <div className="form__form-group mb-10 mt-10">
              <span className="form__form-group-label">Postback URL</span>
              <div className="form__form-group-field no-margin-left no-padding-left">
                <div
                  id="TooltipPostbackField"
                  className='offer-details__field-container'
                >
                  <Field
                    name="postback_url"
                    component="input"
                    type="text"
                    value={postback_url}
                    placeholder={postback_url}
                    disabled
                  />
                </div>
                <UncontrolledTooltip placement="top" target="TooltipPostbackField">
                  {postback_url}
                </UncontrolledTooltip>
                <div className="form__form-group-icon customize-icon-container">
                  <CopyToClipboard id="TooltipLinkPostback" text={postback_url} onCopy={copyTrackbackLink}>
                    <div className="">
                      <a href="javascript:;">
                        <ClipboardFileIcon
                          className="customize-icon"
                        />
                      </a>
                    </div>
                  </CopyToClipboard>
                  <UncontrolledTooltip placement="bottom" target="TooltipLinkPostback">
                    {postback_url}
                  </UncontrolledTooltip>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default reduxForm({
  form: 'trackback_urls_form',
})(OfferTrackUrlPart)
