/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component, Fragment } from 'react';
import numeral from "numeral";
import { connect } from "react-redux";
import store from '../../../App/store'
import DataPaginationTable from '../../../../shared/components/table/DataPaginationTable';
import { LoadableContainer } from "../../../../widgets";
import {
  getSavedOrderOfColumns,
} from '../../../../utils';
import { TOTAL_TEXT } from "../../../../constants";
import { FINANCIAL_HEADS_OFFERS, NON_FINANCIAL_HEADS_OFFERS, DEFAULT_HEAD_COLUMN } from "../../../../constants/offerstTable";
import {FINANCIAL_HEADS_DATE, NON_FINANCIAL_HEADS_DATE} from "../../../../constants/dateTable";

class OfferTable extends Component {
  constructor(props) {
    super(props);
    const users = store.getState().users || {};
    const usersSettings = users.settings || {};
    this.state = {
      usersSettings: users.settings || {},
      heads: [],
      defaultHeads: [
        ...(users && users.currentUser && users.currentUser.show_financial_data ? FINANCIAL_HEADS_OFFERS(usersSettings) : NON_FINANCIAL_HEADS_OFFERS(usersSettings))
      ],
      defaultHeadColumn: {
        ...DEFAULT_HEAD_COLUMN
      },
    }
  }

  componentDidMount() {
  }

  static getDerivedStateFromProps(props, state) {
    const usersSettings = state.usersSettings
    const tables = usersSettings && usersSettings.settings ? usersSettings.settings : []
    const defHeads = props.users && props.users.currentUser && props.users.currentUser.show_financial_data ?
        FINANCIAL_HEADS_DATE(usersSettings) : NON_FINANCIAL_HEADS_DATE(usersSettings)
    const sortedHeads = getSavedOrderOfColumns(defHeads, tables, 'key')
    return {
      heads: [{...state.defaultHeadColumn}, ...sortedHeads],
    }
  }

  prepareTotalDataRow = (offers, heads) => {
    const { defaultHeads } = this.state;
    let newOffersData = [...offers];
    let totalKey = heads[0].key;
    let totalData = {};
    totalData[totalKey] = TOTAL_TEXT;
    defaultHeads.map((columnKey, i) => {
      totalData[columnKey.key] = offers.length !== 0 ? offers.reduce((accumulator, currentValue) => (accumulator + currentValue[columnKey.key]), 0) : 0;
      switch (columnKey.key) {
        case 'ctr':
          totalData.ctr = numeral(totalData.impressions !== 0 ? totalData.clicks / totalData.impressions : 0);
          break;
        case 'lp_ctr':
          totalData.lp_ctr = numeral(totalData.lp_views !== 0 ? totalData.lp_clicks / totalData.lp_views : 0);
          break;
        case 'lp_click_ctr':
          totalData.lp_click_ctr = numeral(totalData.clicks !== 0 ? totalData.lp_clicks / totalData.clicks : 0);
          break;
        case 'cr':
          totalData.cr = numeral(totalData.clicks !== 0 ? totalData.conversions / totalData.clicks : 0);
          break;
        case 'total_conversions_cr':
          totalData.total_conversions_cr = numeral(totalData.clicks !== 0 ? totalData.total_conversions / totalData.clicks : 0);
          break;
        case 'convratetype1':
          totalData.convratetype1 = numeral(totalData.clicks !== 0 ? totalData.convtype1 / totalData.clicks : 0);
          break;
        case 'convratetype2':
          totalData.convratetype2 = numeral(totalData.clicks !== 0 ? totalData.convtype2 / totalData.clicks : 0);
          break;
        case 'convratetype3':
          totalData.convratetype3 = numeral(totalData.clicks !== 0 ? totalData.convtype3 / totalData.clicks : 0);
          break;
        case 'convratetype4':
          totalData.convratetype4 = numeral(totalData.clicks !== 0 ? totalData.convtype4 / totalData.clicks : 0);
          break;
        case 'convratetype5':
          totalData.convratetype5 = numeral(totalData.clicks !== 0 ? totalData.convtype5 / totalData.clicks : 0);
          break;
        case 'convratetype6':
          totalData.convratetype6 = numeral(totalData.clicks !== 0 ? totalData.convtype6 / totalData.clicks : 0);
          break;
        case 'convratetype7':
          totalData.convratetype7 = numeral(totalData.clicks !== 0 ? totalData.convtype7 / totalData.clicks : 0);
          break;
        case 'convratetype8':
          totalData.convratetype8 = numeral(totalData.clicks !== 0 ? totalData.convtype8 / totalData.clicks : 0);
          break;
        case 'convratetype9':
          totalData.convratetype9 = numeral(totalData.clicks !== 0 ? totalData.convtype9 / totalData.clicks : 0);
          break;
        case 'convratetype10':
          totalData.convratetype10 = numeral(totalData.clicks !== 0 ? totalData.convtype10 / totalData.clicks : 0);
          break;
        case 'convratetype11':
          totalData.convratetype11 = numeral(totalData.clicks !== 0 ? totalData.convtype11 / totalData.clicks : 0);
          break;
        case 'convratetype12':
          totalData.convratetype12 = numeral(totalData.clicks !== 0 ? totalData.convtype12 / totalData.clicks : 0);
          break;
        case 'convratetype13':
          totalData.convratetype13 = numeral(totalData.clicks !== 0 ? totalData.convtype13 / totalData.clicks : 0);
          break;
        case 'convratetype14':
          totalData.convratetype14 = numeral(totalData.clicks !== 0 ? totalData.convtype14 / totalData.clicks : 0);
          break;
        case 'convratetype15':
          totalData.convratetype15 = numeral(totalData.clicks !== 0 ? totalData.convtype15 / totalData.clicks : 0);
          break;
        case 'convratetype16':
          totalData.convratetype16 = numeral(totalData.clicks !== 0 ? totalData.convtype16 / totalData.clicks : 0);
          break;
        case 'convratetype17':
          totalData.convratetype17 = numeral(totalData.clicks !== 0 ? totalData.convtype17 / totalData.clicks : 0);
          break;
        case 'convratetype18':
          totalData.convratetype18 = numeral(totalData.clicks !== 0 ? totalData.convtype18 / totalData.clicks : 0);
          break;
        case 'convratetype19':
          totalData.convratetype19 = numeral(totalData.clicks !== 0 ? totalData.convtype19 / totalData.clicks : 0);
          break;
        case 'convratetype20':
          totalData.convratetype20 = numeral(totalData.clicks !== 0 ? totalData.convtype20 / totalData.clicks : 0);
          break;
        case 'approved_rate':
          totalData.approved_rate = numeral(totalData.clicks !== 0 ? totalData.approved / totalData.clicks : 0);
          break;
        case 'pending_rate':
          totalData.pending_rate = numeral(totalData.clicks !== 0 ? totalData.pending / totalData.clicks : 0);
          break;
        case 'declined_rate':
          totalData.declined_rate = numeral(totalData.clicks !== 0 ? totalData.declined / totalData.clicks : 0);
          break;
        case 'other_rate':
          totalData.other_rate = numeral(totalData.clicks !== 0 ? totalData.other / totalData.clicks : 0);
          break;
        case 'convcpatype1':
          totalData.convcpatype1 = numeral(totalData.convtype1 !== 0 ? totalData.cost / totalData.convtype1 : 0);
          break;
        case 'convcpatype2':
          totalData.convcpatype2 = numeral(totalData.convtype2 !== 0 ? totalData.cost / totalData.convtype2 : 0);
          break;
        case 'convcpatype3':
          totalData.convcpatype3 = numeral(totalData.convtype3 !== 0 ? totalData.cost / totalData.convtype3 : 0);
          break;
        case 'convcpatype4':
          totalData.convcpatype4 = numeral(totalData.convtype4 !== 0 ? totalData.cost / totalData.convtype4 : 0);
          break;
        case 'convcpatype5':
          totalData.convcpatype5 = numeral(totalData.convtype5 !== 0 ? totalData.cost / totalData.convtype5 : 0);
          break;
        case 'convcpatype6':
          totalData.convcpatype6 = numeral(totalData.convtype6 !== 0 ? totalData.cost / totalData.convtype6 : 0);
          break;
        case 'convcpatype7':
          totalData.convcpatype7 = numeral(totalData.convtype7 !== 0 ? totalData.cost / totalData.convtype7 : 0);
          break;
        case 'convcpatype8':
          totalData.convcpatype8 = numeral(totalData.convtype8 !== 0 ? totalData.cost / totalData.convtype8 : 0);
          break;
        case 'convcpatype9':
          totalData.convcpatype9 = numeral(totalData.convtype9 !== 0 ? totalData.cost / totalData.convtype9 : 0);
          break;
        case 'convcpatype10':
          totalData.convcpatype10 = numeral(totalData.convtype10 !== 0 ? totalData.cost / totalData.convtype10 : 0);
          break;
        case 'convcpatype11':
          totalData.convcpatype11 = numeral(totalData.convtype11 !== 0 ? totalData.cost / totalData.convtype11 : 0);
          break;
        case 'convcpatype12':
          totalData.convcpatype12 = numeral(totalData.convtype12 !== 0 ? totalData.cost / totalData.convtype12 : 0);
          break;
        case 'convcpatype13':
          totalData.convcpatype13 = numeral(totalData.convtype13 !== 0 ? totalData.cost / totalData.convtype13 : 0);
          break;
        case 'convcpatype14':
          totalData.convcpatype14 = numeral(totalData.convtype14 !== 0 ? totalData.cost / totalData.convtype14 : 0);
          break;
        case 'convcpatype15':
          totalData.convcpatype15 = numeral(totalData.convtype15 !== 0 ? totalData.cost / totalData.convtype15 : 0);
          break;
        case 'convcpatype16':
          totalData.convcpatype16 = numeral(totalData.convtype16 !== 0 ? totalData.cost / totalData.convtype16 : 0);
          break;
        case 'convcpatype17':
          totalData.convcpatype17 = numeral(totalData.convtype17 !== 0 ? totalData.cost / totalData.convtype17 : 0);
          break;
        case 'convcpatype18':
          totalData.convcpatype18 = numeral(totalData.convtype18 !== 0 ? totalData.cost / totalData.convtype18 : 0);
          break;
        case 'convcpatype19':
          totalData.convcpatype19 = numeral(totalData.convtype19 !== 0 ? totalData.cost / totalData.convtype19 : 0);
          break;
        case 'convcpatype20':
          totalData.convcpatype20 = numeral(totalData.convtype20 !== 0 ? totalData.cost / totalData.convtype20 : 0);
          break;
        case 'convaovtype1':
          totalData.convoavtype1 = numeral(totalData.convtype1 !== 0 ? totalData.revenuetype1 / totalData.convtype1 : 0);
          break;
        case 'convaovtype2':
          totalData.convoavtype2 = numeral(totalData.convtype2 !== 0 ? totalData.revenuetype2 / totalData.convtype2 : 0);
          break;
        case 'convaovtype3':
          totalData.convoavtype3 = numeral(totalData.convtype3 !== 0 ? totalData.revenuetype3 / totalData.convtype3 : 0);
          break;
        case 'convaovtype4':
          totalData.convoavtype4 = numeral(totalData.convtype4 !== 0 ? totalData.revenuetype4 / totalData.convtype4 : 0);
          break;
        case 'convaovtype5':
          totalData.convaovtype5 = numeral(totalData.convtype5 !== 0 ? totalData.revenuetype5 / totalData.convtype5 : 0);
          break;
        case 'convaovtype6':
          totalData.convaovtype6 = numeral(totalData.convtype6 !== 0 ? totalData.revenuetype6 / totalData.convtype6 : 0);
          break;
        case 'convaovtype7':
          totalData.convaovtype7 = numeral(totalData.convtype7 !== 0 ? totalData.revenuetype7 / totalData.convtype7 : 0);
          break;
        case 'convaovtype8':
          totalData.convaovtype8 = numeral(totalData.convtype8 !== 0 ? totalData.revenuetype8 / totalData.convtype8 : 0);
          break;
        case 'convaovtype9':
          totalData.convaovtype9 = numeral(totalData.convtype9 !== 0 ? totalData.revenuetype9 / totalData.convtype9 : 0);
          break;
        case 'convaovtype10':
          totalData.convoavtype10 = numeral(totalData.convtype10 !== 0 ? totalData.revenuetype10 / totalData.convtype10 : 0);
          break;
        case 'convaovtype11':
          totalData.convoavtype11 = numeral(totalData.convtype11 !== 0 ? totalData.revenuetype11 / totalData.convtype11 : 0);
          break;
        case 'convaovtype12':
          totalData.convoavtype12 = numeral(totalData.convtype12 !== 0 ? totalData.revenuetype12 / totalData.convtype12 : 0);
          break;
        case 'convaovtype13':
          totalData.convoavtype13 = numeral(totalData.convtype13 !== 0 ? totalData.revenuetype13 / totalData.convtype13 : 0);
          break;
        case 'convaovtype14':
          totalData.convoavtype14 = numeral(totalData.convtype14 !== 0 ? totalData.revenuetype14 / totalData.convtype14 : 0);
          break;
        case 'convaovtype15':
          totalData.convoavtype15 = numeral(totalData.convtype15 !== 0 ? totalData.revenuetype15 / totalData.convtype15 : 0);
          break;
        case 'convaovtype16':
          totalData.convoavtype16 = numeral(totalData.convtype16 !== 0 ? totalData.revenuetype16 / totalData.convtype16 : 0);
          break;
        case 'convaovtype17':
          totalData.convoavtype17 = numeral(totalData.convtype17 !== 0 ? totalData.revenuetype17 / totalData.convtype17 : 0);
          break;
        case 'convaovtype18':
          totalData.convoavtype18 = numeral(totalData.convtype18 !== 0 ? totalData.revenuetype18 / totalData.convtype18 : 0);
          break;
        case 'convaovtype19':
          totalData.convoavtype19 = numeral(totalData.convtype19 !== 0 ? totalData.revenuetype19 / totalData.convtype19 : 0);
          break;
        case 'convaovtype20':
          totalData.convoavtype20 = numeral(totalData.convtype20 !== 0 ? totalData.revenuetype20 / totalData.convtype20 : 0);
          break;
        case 'roi':
          totalData.roi = numeral(totalData.cost !== 0 ? totalData.profit / totalData.cost : 0);
          break;
        case 'cpa':
          totalData.cpa = numeral(totalData.conversions !== 0 ? totalData.cost / totalData.conversions : 0);
          break;
        case 'cpc':
          totalData.cpc = numeral(totalData.clicks !== 0 ? totalData.cost / totalData.clicks : 0);
          break;
        case 'cpt':
          totalData.cpt = numeral(totalData.transactions !== 0 ? totalData.cost / totalData.transactions : 0);
          break;
        case 'epc':
          totalData.epc = numeral(totalData.clicks !== 0 ? totalData.revenue / totalData.clicks : 0);
          break;
        default:
      }
    });
    newOffersData.push(totalData)
    return [...newOffersData]
  };

  render() {
    const { reportData, loading, loaded } = this.props;
    const { heads } = this.state;
    const offersItems = reportData || []
    const resource = this.prepareTotalDataRow(offersItems, heads);

    return (
      <Fragment>
        <LoadableContainer
          loading={loading}
          loaded={loaded}
          style={{
            position: 'relative',
            width: '100%',
            maxHeight: '350px'
          }}
        >
          {resource &&
          <DataPaginationTable
            lastRowStyle={{
              fontWeight: 'bolder',
              backgroundColor: '#f5f5f5',
            }}
            evenRowStyle={{
              backgroundColor: '#f9f9f9',
            }}
            heads={heads}
            rows={resource || []}
          />
          }
        </LoadableContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
})

export default connect(mapStateToProps)(OfferTable)
