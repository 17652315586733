import { loadReport } from '../../utils/apiAdapter'
import { DEFAULT_GROUPING_BY_DATE, DEFAULT_TRACKS_VIEW } from '../../constants/index'
import { errorOccuredWithNotificationAction as errorOccuredAction } from './errors'

export const LOAD_REPORT = 'LOAD_REPORT';
export const ADD_REPORT = 'ADD_REPORT';

export const loadReportAction = (scope, filters = {}, isDefaultGrouping=false) => (
  dispatch => {
    dispatch({ type: LOAD_REPORT, scope, filters });
    const groupingWithDefault = filters.group.filter(el => el !== DEFAULT_GROUPING_BY_DATE[0])
    const grouping = isDefaultGrouping ? [ ...groupingWithDefault, ...DEFAULT_GROUPING_BY_DATE ] : [ ...filters.group ]
    let params = {
      ...filters,
      group: grouping.join(','),
      date_from: filters.date_from.format('YYYY-MM-DD'),
      date_to: filters.date_to.format('YYYY-MM-DD'),
    };
    if (params.group.length === 0) {
      delete params.group
    }
    return loadReport(params, (items, total) => dispatch(addReportDataAction(scope, { items, total })))
            .catch(error => dispatch(errorOccuredAction(error)))
  }
);

export const loadReportChartAction = (scope, filters = {}, isDefaultGrouping=true) => (
  dispatch => {
    const groupingWithDefault = filters.group.filter(el => el !== DEFAULT_GROUPING_BY_DATE[0])
    const grouping = isDefaultGrouping ? [ ...DEFAULT_GROUPING_BY_DATE ] : [ ...filters.group ]
    let params = {
      ...filters,
      group: grouping.join(','),
      date_from: filters.date_from.format('YYYY-MM-DD'),
      date_to: filters.date_to.format('YYYY-MM-DD'),
      tracks_view: DEFAULT_TRACKS_VIEW,
    };
    if (params.group.length === 0) {
      delete params.group
    }
    return loadReport(params, (items, total) => {})
            .catch(error => dispatch(errorOccuredAction(error)))
  }
);

export const addReportDataAction = (scope, data) => ({ type: ADD_REPORT, data, scope });

