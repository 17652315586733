import { LOAD_OFFERS, ADD_OFFERS, FILTER_OFFERS } from './../actions/offersActions'
import moment from "moment";

export const STATUS_ACTIVE   = 1
export const STATUS_ARCHIVED = 2

const initialState = {
    items: [],
    filters: {
        date_from: moment().subtract(29, 'days').startOf("day"),
        date_to: moment().endOf("day"),
    },
    loading: false,
    loaded: false,
}

export const offers = (state=initialState, action) => {
    switch (action.type) {

        case LOAD_OFFERS:
            return {
                ...state,
                loading: true,
                loaded: false
            }

        case ADD_OFFERS:
            return {
                ...state,
                loading: false,
                loaded: false,
                items: action.data.items,
                total: action.data.total
            }

        case FILTER_OFFERS:
            return {
                ...state,
                filters: action.filters
            }

        default:
            return state
    }
}
