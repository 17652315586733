import numeral from 'numeral';
import {
  renderConvTypeAovTitle,
} from '../utils';
import { sum, sumHiddenColumn } from '../utils/Helpers';
import React from "react";

/**
 * @param user {object}
 * @param offers {array}
 * @param currencySign {string}
 * @param convTypeCount {number}
 * @returns {array}
 */
export const renderConvTypeAov = (user, offers, currencySign = '', convTypeCount = 20) => (
  Array.from({ length: convTypeCount }, (el, i) => i + 1)
  .map((count) => (
    {
      id: `convaovtype${count}`,
      title: renderConvTypeAovTitle(`convtype${count}`, 'conv', user),
      className: '',
      minWidth: 120,
      width: 120,
      accessor: `convaovtype${count}`,
      Cell: data => numeral(data.row[`revenuetype${count}`] && data.row[`convtype${count}`] !== 0 ? data.row[`revenuetype${count}`] / data.row[`convtype${count}`] : 0).format('0.00[00]'),
      Header: () => <span>{renderConvTypeAovTitle(`convtype${count}`,'conv', user)}</span>,
      Footer: () => {
        const conversions = sum((offers || []).map(r => r[`convtype${count}`]))
        const revenue = sum((offers || []).map(r => r[`revenuetype${count}`]))
        return numeral(conversions !== 0 ? revenue / conversions : 0).format('0.00[00]')
      },
      aggregate: (values, rows) => {
        const conversions = sumHiddenColumn(rows, `convtype${count}`);
        const revenue = sumHiddenColumn(rows, `revenuetype${count}`);
        return conversions !== 0 ? revenue / conversions : 0;
      },
    }
  ))
);

/**
 * @param user {object}
 * @param currencySign {string}
 * @param convTypeCount {number}
 * @returns {array}
 */
export const renderStatConvTypeAov = (user, currencySign = '', convTypeCount = 20) => (
  Array.from({ length: convTypeCount }, (el, i) => i + 1)
  .map((count) => (
    {
      id: `convaovtype${count}`,
      title: renderConvTypeAovTitle(`convtype${count}`, 'conv', user),
      className: '',
      accessor: `stat.convaovtype${count}`,
      minWidth: 120,
      width: 120,
      Cell: row => numeral(row.original.stat[`revenuetype${count}`] && row.original.stat[`convtype${count}`] !== 0 ? row.original.stat[`revenuetype${count}`] / row.original.stat[`convtype${count}`] : 0).format('0.00[00]'),
      Header: () =>
            <span>
              {renderConvTypeAovTitle(`convtype${count}`, 'conv', user)}
            </span>,
      Footer: t => {
        const conversions = sum(t.data.map(r => r[`convtype${count}`]))
        const revenue = sum(t.data.map(r => r[`revenuetype${count}`]))
        return numeral(conversions !== 0 ? revenue / conversions : 0).format('0.00[00]')
      },
    }
  ))
);

/**
 * @param usersSettings {object}
 * @param currencySign {string}
 * @param convTypeCount {number}
 * @returns {array}
 */
export const renderDifConvTypeAov = (usersSettings, currencySign = '', convTypeCount = 20) => (
  Array.from({ length: convTypeCount }, (el, i) => i + 1)
  .map((count) => (
    {
      key: `convaovtype${count}`,
      name: renderConvTypeAovTitle(`convtype${count}`,'conv', usersSettings),
      formatter: ({ row }) => numeral(row[`revenuetype${count}`] && row[`convtype${count}`] !== 0 ? row[`revenuetype${count}`] / row[`convtype${count}`] : 0).format('0.00[00]'),
      width: 180,
      resizable: true,
      sortable: false,
    }
  ))
);
