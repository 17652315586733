import moment from 'moment'
import { LOAD_CONVERSIONS_STAT, ADD_CONVERSIONS_STAT, FILTER_CONVERSIONS_STAT } from './../actions/conversionsStatActions'

export const STATUS_ACTIVE   = 1
export const STATUS_ARCHIVED = 2

const initialState = {
	items: [],
	filters: {
		date_from: moment().subtract(29, 'days').startOf("day"),
		date_to: moment().endOf("day"),
	},
	loading: false,
	loaded: false,
}

export const convstat = (state=initialState, action) => {
	switch (action.type) {

		case LOAD_CONVERSIONS_STAT:
			return {
				...state,
				loading: true,
				loaded: false,
			}

		case ADD_CONVERSIONS_STAT:
			return {
				...state,
				loading: false,
				loaded: false,
				items: action.data.items
			}

		case FILTER_CONVERSIONS_STAT:
			return {
				filters: action.filters
			}

		default:
			return state

	}
}
