import moment from 'moment'
import { LOAD_DASHBOARD_REPORT, ADD_DASHBOARD_REPORT, ADD_DASHBOARD_GRAPHS_REPORT } from './../actions/dashboardsActions'
import { CONVERSION_DASHBOARD_SCOPE, TRAFFIC_DASHBOARD_SCOPE } from '../../constants/dashboard'

const DEFAULT_GROUPING = 'date';
const DEFAULT_FILTERS = {
  date_from: moment().subtract(29, 'days').startOf("day"),
  date_to: moment().endOf("day"),
  group: [DEFAULT_GROUPING],
};

const initialState = {
  [CONVERSION_DASHBOARD_SCOPE]: [],
  [`${CONVERSION_DASHBOARD_SCOPE}_by_date`]: [],
  [`${CONVERSION_DASHBOARD_SCOPE}_filters`]: { ...DEFAULT_FILTERS },
  [`${CONVERSION_DASHBOARD_SCOPE}_loading`]: false,
  [`${CONVERSION_DASHBOARD_SCOPE}_loaded`]: false,

  [TRAFFIC_DASHBOARD_SCOPE]: [],
  [`${TRAFFIC_DASHBOARD_SCOPE}_by_date`]: [],
  [`${TRAFFIC_DASHBOARD_SCOPE}_filters`]: { ...DEFAULT_FILTERS },
  [`${TRAFFIC_DASHBOARD_SCOPE}_loading`]: false,
  [`${TRAFFIC_DASHBOARD_SCOPE}_loaded`]: false,
};

export const dashboards = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_DASHBOARD_REPORT:
      return {
        ...state,
        [action.scope]: [],
        [`${action.scope}_filters`]: action.filters,
        [`${action.scope}_loading`]: true,
        [`${action.scope}_loaded`]: false,
      };

    case ADD_DASHBOARD_REPORT:
      return {
        ...state,
        [action.scope]: action.data.items,
        [`${action.scope}_total`]: action.data.total,
        [`${action.scope}_loading`]: false,
        [`${action.scope}_loaded`]: false,
      };

    case ADD_DASHBOARD_GRAPHS_REPORT:
      return {
        ...state,
        [`${action.scope}_by_date`]: action.data.items,
        [`${action.scope}_total`]: action.data.total,
        [`${action.scope}_loading`]: false,
        [`${action.scope}_loaded`]: false,
      };

    default:
      return state
  }
};
