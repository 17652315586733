import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import SignUpForm from './components/SignUpForm';
import {connect} from "react-redux";

class SignUp extends Component {

  render(){
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">
                <div className="account__logo">
                    {this.props.settings && this.props.settings.logo_url ?
                        <img src={this.props.settings.logo_url} className="account__rtk-logo-by-user" /> :
                        <div className="account__rtk-logo" />
                    }
                </div>
                Registration
               </h3>
                <h4 className="account__subhead subhead">Your credentials</h4>
            </div>
            <SignUpForm history={this.props.history} onSubmit />
           </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    settings: state.users ? state.users.settings : {}
})

export default connect(mapStateToProps)(SignUp)

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles