import { LOAD_PAYMENTS, ADD_PAYMENTS } from './../actions/paymentsActions'

const initialState = {
    items: [],
    loading: true,
}

export const payments = (state=initialState, action) => {
    switch (action.type) {

        case LOAD_PAYMENTS:
            return {
                ...state,
                loading: true
            }

        case ADD_PAYMENTS:
            return {
                ...state,
                loading: false,
                items: action.data.items
            }


        default:
            return state

    }
}
