const MAX_GROUPING_VALUES = 3;
const INITIAL_PAGE_NUMBER = 1;
const INITIAL_ROWS_COUNT = 10;
const INITIAL_ROWS_COUNT_OFFERS = 100;
const UNAUTHORIZED_USER_STATUS = 401;
const DATE_FORMAT = 'YYYY-MM-DD'
const DATE_FORMAT_HOUR = 'YYYY-MM-DD hh:mm'
const LAST_HOUR_FLAG_VALUE = 'lasthour'
const NA_VALUE = 'N/A';
const EMPTY_ROW_STRING_VALUE = '';
const CURRENCY = 'USD'
const TOTAL_TEXT = 'Total:'
const SESSION_OBJECT_KEY = 'sessionObject'
const SETTINGS_TIME_EXPIRED_MIN = 10
const ALLOWED_TRAFFIC = 'All traffic types'
const ALL_OS_DEVICES_ALLOWED = 'All devices & OS allowed'
const ALL_COUNTRIES_ALLOWED = 'All countries allowed'
const ALL_TYPES_ALLOWED = 'All types allowed'
const COPY_NOTIFICATION = 'URL copied to clipboard'
const REV_SHARE_COST_MODEL = 'RevShare'
const DASHBOARD_BY_DAY = 'by_date'
const DASHBOARD_BY_OFFER = 'by_offer'
const STATUS_ACTIVE = 1
const STATUS_ARCHIVED = 2
const STATUS_DELETED = 3
const CAMPAIGN_STATUSES = {
  [STATUS_ACTIVE]: 'Active',
  [STATUS_ARCHIVED]: 'Archived',
  [STATUS_DELETED]: 'Deleted',
}
const DEFAULT_GROUPING_BY_DATE = ['date']
const DEFAULT_TRACKS_VIEW = true
const GROUPING_BY_OFFER = ['offer']
const STATUS_OFFER_DELETED = 3
const LANGUAGES = [
  { value: 'ch', label: '中国' },
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' },
  { value: 'ru', label: 'Russian' },
]
export {
  MAX_GROUPING_VALUES,
  INITIAL_PAGE_NUMBER,
  INITIAL_ROWS_COUNT,
  NA_VALUE,
  EMPTY_ROW_STRING_VALUE,
  CURRENCY,
  ALLOWED_TRAFFIC,
  REV_SHARE_COST_MODEL,
  STATUS_ACTIVE,
  STATUS_ARCHIVED,
  STATUS_DELETED,
  CAMPAIGN_STATUSES,
  COPY_NOTIFICATION,
  ALL_OS_DEVICES_ALLOWED,
  ALL_COUNTRIES_ALLOWED,
  ALL_TYPES_ALLOWED,
  STATUS_OFFER_DELETED,
  TOTAL_TEXT,
  SESSION_OBJECT_KEY,
  SETTINGS_TIME_EXPIRED_MIN,
  INITIAL_ROWS_COUNT_OFFERS,
  UNAUTHORIZED_USER_STATUS,
  DATE_FORMAT,
  DATE_FORMAT_HOUR,
  LAST_HOUR_FLAG_VALUE,
  DASHBOARD_BY_DAY,
  DASHBOARD_BY_OFFER,
  DEFAULT_GROUPING_BY_DATE,
  DEFAULT_TRACKS_VIEW,
  GROUPING_BY_OFFER,
  LANGUAGES,
}
