import store from '../../containers/App/store'
import { loadOffers } from '../../utils/apiAdapter'
import { prepareDateFromTo } from '../../utils/index'
import { errorOccuredWithNotificationAction as errorOccuredAction } from './errors'

import { STATUS_OFFER_DELETED } from '../../constants';
import { DATE_FORMAT_HOUR, LAST_HOUR_FLAG_VALUE } from '../../constants/index'

export const LOAD_OFFERS   = 'LOAD_OFFERS'
export const ADD_OFFERS    = 'ADD_OFFERS'
export const FILTER_OFFERS = 'FILTER_OFFERS'

export const loadOffersAction = (dispatch) => {
    dispatch({ type: LOAD_OFFERS });
    const filters = store.getState().offers.filters;
    const date = prepareDateFromTo(filters, LAST_HOUR_FLAG_VALUE, DATE_FORMAT_HOUR)

    return loadOffers({
        title:         filters.title,
        date_from:     date.date_from,
        date_to:       date.date_to,
    }, (itemsData, total) => {
        const preparedItems = itemsData || [];
        const items = preparedItems.filter((el,i) => el && el.status !== STATUS_OFFER_DELETED);
        dispatch(addCampaignsAction({ items, total }))
    })
    .catch(error => dispatch(errorOccuredAction(error)))
}

export const addCampaignsAction = data => ({ type: ADD_OFFERS, data: data })

export const filterAction = filters => ({ type: FILTER_OFFERS, filters })
