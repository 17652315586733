/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component, Fragment } from 'react'
import { Card, CardBody, Col, Table } from 'reactstrap'

import {
  NA_VALUE,
  CAMPAIGN_STATUSES,
  CURRENCY,
  ALLOWED_TRAFFIC,
  REV_SHARE_COST_MODEL,
  ALL_OS_DEVICES_ALLOWED,
  ALL_COUNTRIES_ALLOWED,
  ALL_TYPES_ALLOWED,
} from '../../../../constants'

const PreviewLinkFormatter = ({ value }) => {
  return <a target={'blank'} href={value}>(Preview)</a>
}

class OfferGeneralDetails extends Component {
  constructor (props) {
    super(props)
  }

  _renderLikeTags = (data) => {
    return data.map((el, i) => <span className={`${i !== 0 ? 'pl-10 pr-10' : 'pr-10'}`}>{el}</span>)
  }

  _renderPublisherCategories = (pubDetails) => {
    let categories = NA_VALUE
    if (pubDetails && pubDetails.categories) {
      categories = this._renderLikeTags(pubDetails.categories)
    }

    return categories
  }

  _renderFields = (campaign) => {
    let campaignDetails = campaign
    const pubDetails = campaign.publisher_details
    let allowedTraffic = ALLOWED_TRAFFIC
    let publisherOS = pubDetails && pubDetails.os ? pubDetails.os.join(', ') : ALL_OS_DEVICES_ALLOWED
    if (pubDetails && pubDetails.os && pubDetails.os.length === 1) {
      publisherOS = pubDetails.os[0] !== '' ? pubDetails.os.join(', ') : ALL_OS_DEVICES_ALLOWED
    } else if (pubDetails && pubDetails.os && pubDetails.os.length === 0) {
      publisherOS = ALL_OS_DEVICES_ALLOWED
    }
    let publisherCountries = pubDetails && pubDetails.countries ? pubDetails.countries.filter((el) => el !== '').join(', ') : ALL_COUNTRIES_ALLOWED
    if (pubDetails && pubDetails.countries && pubDetails.countries.length === 1) {
      publisherCountries = pubDetails.countries[0] !== '' ? pubDetails.countries.join(', ') : ALL_COUNTRIES_ALLOWED
    } else if (pubDetails && pubDetails.countries && pubDetails.countries.length === 0) {
      publisherCountries = ALL_COUNTRIES_ALLOWED
    }
    const campaignCap = campaignDetails.cap ? campaignDetails.cap : NA_VALUE
    let campaignStatus = NA_VALUE
    if (campaignDetails.status && CAMPAIGN_STATUSES[campaignDetails.status]) {
      campaignStatus = CAMPAIGN_STATUSES[campaignDetails.status]
    }
    if (pubDetails && pubDetails.sources && pubDetails.sources.length !== 0) {
      allowedTraffic = this._renderLikeTags(pubDetails.sources)
    }

    if (campaignDetails.cost_model === '') {
      campaignDetails.cost_model = NA_VALUE
    } else if (campaignDetails.cost_model === REV_SHARE_COST_MODEL) {
      campaignDetails.cpc = `${campaignDetails.rev_share}%`
    } else {
      campaignDetails.cpc = `${CURRENCY} ${campaignDetails.cpc}`
    }
    return (
      <Table className="table--bordered">
        <tbody>
        <tr className='custom'>
          <td>Payment:</td>
          <td>
            <span className='pr-10'>
              {campaignDetails.payment && campaignDetails.payment.amount ? campaignDetails.payment.amount+" $" : 'N/A'}
            </span>
          </td>
        </tr>
        <tr className='custom'>
          <td>Default conversion status</td>
          <td>{campaignDetails.default_conversion_status || 'N/A'}</td>
        </tr>
        <tr className='custom-no-border'>
          <td>Cl Cap</td>
          <td>{campaignDetails.clcap || '0'}</td>
        </tr>
        <tr className='custom-no-border'>
          <td>Cl Cap Alert</td>
          <td>{campaignDetails.clcap_alert ? 'true' : 'false'}</td>
        </tr>
        <tr className='custom-no-border'>
          <td>Cap</td>
          <td>{campaignDetails.cap || '0'}</td>
        </tr>
        <tr className='custom-no-border'>
          <td>Cap Alert</td>
          <td>{campaignDetails.cap_alert ? 'true' : 'false'}</td>
        </tr>
        <tr className='custom-no-border'>
          <td>Click Cap</td>
          <td>{campaignDetails.click_cap || '0'}</td>
        </tr>
        <tr className='custom-no-border'>
          <td>Click Cap Type</td>
          <td>{campaignDetails.click_cap_type || 'N/A'}</td>
        </tr>
        <tr className='custom-no-border'>
          <td>Click Cap Period</td>
          <td>{campaignDetails.click_cap_period || 'N/A'}</td>
        </tr>
        </tbody>
      </Table>
    )
  }

  render () {
    const { resource } = this.props;
    const pubDetails = resource.publisher_details
    const previewUrl = pubDetails && pubDetails.preview_url ? pubDetails.preview_url : ''

    return (
      <Card>
        <CardBody
          className="panel__body"
          style={{
            border: '0.5px solid rgb(232, 232, 232)',
            boxShadow: '0 10px 30px 1px rgba(0, 0, 0, 0.11)',
          }}
        >
          <div className="card__title">
            <h5 className="bold-text">Campaign Details</h5>
          </div>
          <div className="offer-details__resource-title mb-20">
            <h5 className="bold-text">{resource.title}</h5>
          </div>
          <Col md={10} lg={10} className='no-padding-left'>
            {this._renderFields(resource)}
          </Col>
        </CardBody>
      </Card>
    )
  }
}

export default OfferGeneralDetails
