import { loadConversionsStat } from '../../utils/apiAdapter'
import moment from 'moment'
import store from '../../containers/App/store'

export const LOAD_CONVERSIONS_STAT   = 'LOAD_CONVERSIONS_STAT'
export const ADD_CONVERSIONS_STAT    = 'ADD_CONVERSIONS_STAT'
export const FILTER_CONVERSIONS_STAT = 'FILTER_CONVERSIONS_STAT'

export const loadConversionsStatAction = (dispatch) => {
	dispatch({ type: LOAD_CONVERSIONS_STAT })

	return loadConversionsStat({
		// status:        store.getState().campaigns.filters.status,
		date_from:     moment(store.getState().convstat.filters.date_from).format('YYYY-MM-DD'),
		date_to:       moment(store.getState().convstat.filters.date_to).format('YYYY-MM-DD'),
	}, (items, total) => dispatch(addConversionsStatAction({ items, total })))
}

export const addConversionsStatAction = data => ({ type: ADD_CONVERSIONS_STAT, data: data })

export const filterConversionsAction = filters => ({ type: FILTER_CONVERSIONS_STAT, filters })