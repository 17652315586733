/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component, Fragment } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import numeral from 'numeral';

import store from '../../App/store'
import { loadOffersAction } from '../../../redux/actions/offersActions'

import OffersFilters from './OffersFilters';
import CustomizableTable from '../../../common/table/customizableTable';
import Pagination from '../../../shared/components/pagination/Pagination';
import {
  getSavedOrderOfColumns,
  renderRevenueTypeTitle,
  renderConvTypeTitle,
  renderConvTypeRateTitle,
  renderConvTypeCpaTitle,
} from '../../../utils';
import { renderStatConvTypeAov } from "../../../utils/columns";
import { sum } from '../../../utils/Helpers';
import { LoadableContainer } from "../../../widgets";

import {
  INITIAL_PAGE_NUMBER,
  INITIAL_ROWS_COUNT,
  TOTAL_TEXT,
} from '../../../constants';
import { CONVTYPE_COUNT } from '../../../constants/columns';

const LinkFormatter = ({ value, original }) => {
  return TOTAL_TEXT === value ? value : <Link to={`/offers/${original.id}`} className='custom-link-element'>{value}</Link>
}

const ReportLinkFormatter = ({ value, original }) => {
  return original.id ? <Link to={`/offers/report/${original.id}`} className='custom-link-element'>Campaign Report</Link> : ''
}


class OffersTable extends Component {
  constructor (props) {
    super(props);
    this.state = {
      heads: [],
      defaultHeads: [
        {
          title: 'Report',
          accessor: 'report',
          minWidth: 140,
          width: 140,
          Header: () => <span>Report</span>,
          Cell: ReportLinkFormatter,
        },
      ],
      statHeads: [],
      defaultHeadColumn: {
        title: 'Title',
        Header: () => <span>Title</span>,
        accessor: 'title',
        minWidth: 500,
        width: 500,
        Cell: LinkFormatter,
        Footer: <span>Total:</span>,
      },
      rows: [],
      rowsToShow: [],
      pageOfItems: INITIAL_PAGE_NUMBER,
      itemsToShow: INITIAL_ROWS_COUNT,
      columnsRenderer: this._renderOffersColumns,
    };
  }

  static getDerivedStateFromProps (props, state) {
    const usersSettings = props.users && props.users.settings || {}
    const tables = usersSettings && usersSettings.settings ? usersSettings.settings : []
    const sortedHeads = getSavedOrderOfColumns(state.columnsRenderer(props.offers.items, usersSettings), tables, 'id')
    return {
      ...state,
      rows: props.offers.items,
      heads: [{...state.defaultHeadColumn}, ...state.defaultHeads, ...sortedHeads],
    }
  }

  componentDidMount () {
    store.dispatch(loadOffersAction).then((data) => {
      const { pageOfItems, itemsToShow } = this.state;
      const currentPageRows = this.filterRows(data, pageOfItems, itemsToShow);
      this.setState({ rows: data, rowsToShow: currentPageRows });
    })
  }

  handleApplyFilters = () => {
    store.dispatch(loadOffersAction).then((data) => {
      const { pageOfItems, itemsToShow } = this.state;
      const currentPageRows = this.filterRows(data, pageOfItems, itemsToShow);
      this.setState({ rows: data, rowsToShow: currentPageRows });
    })
  }

  prepareCompanyData = (data) => {
    let items = data || [];
    let itemsToRender = [];
    itemsToRender = items.map((el) => {
      if (el.cost_model === '') {
        el.cost_model = 'N/A'
      } else if (el.cost_model === 'RevShare') {
        el.cpc = el.rev_share + '%'
      } else {
        el.cpc = el.cpc
      }
      el.cap = 'N/A';
      el.availability = 'Public';
      return el
    });
    return itemsToRender;
  };

  _renderOffersColumns = (offers, user) => {
    return [
      {
        id: 'impressions',
        title: 'Impressions',
        className: '',
        accessor: 'stat.impressions',
        minWidth: 120,
        width: 120,
        Header: () => <span>Impressions</span>,
        Cell: row => (row.value || 0),
        Footer: footer => sum((offers || []).map(row => row.impressions)),
      },
      {
        id: 'impressions_visible',
        title: 'Visible Impressions',
        className: '',
        accessor: 'stat.impressions_visible',
        minWidth: 120,
        width: 120,
        Header: () => <span>Visible Impressions</span>,
        Cell: row => (row.value || 0),
        Footer: footer => sum((offers || []).map(row => row.impressions_visible)),
      },
      {
        id: 'clicks',
        title: 'Clicks',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.clicks',
        Header: () => <span>Clicks</span>,
        Cell: row => (row.value || 0),
        Footer: footer => sum((offers || []).map(row => row.clicks)),
      },
      {
        id: 'unique_clicks',
        title: 'Unique Clicks',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.unique_clicks',
        Header: () => <span>Unique Clicks</span>,
        Cell: row => (row.value || 0),
        Footer: footer => sum((offers || []).map(row => row.unique_clicks)),
      },
      {
        id: 'ctr',
        title: 'CTR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.ctr',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>CTR</span>,
        Footer: footer => {
          const impressions = sum((offers || []).map(row => row.impressions))
          const clicks = sum((offers || []).map(row => row.clicks))
          return numeral(impressions !== 0 ? clicks / impressions : 0).format('0.00%')
        },
      },
      {
        id: 'lp_views',
        title: 'LP Views',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.lp_views',
        description: 'Landing page views',
        Cell: row => (row.value || 0),
        Header: () => <span>LP Views</span>,
        Footer: footer => sum((offers || []).map(row => row.lp_views)),
      },
      {
        id: 'prelp_views',
        title: 'PreLP Views',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.prelp_views',
        description: 'PreLP Views',
        Cell: row => (row.value || 0),
        Header: () => <span>PreLP Views</span>,
        Footer: footer => sum((offers || []).map(row => row.prelp_views)),
      },
      {
        id: 'lp_clicks',
        title: 'LP clicks',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.lp_clicks',
        description: 'Clicks from Lander to offer',
        Cell: row => (row.value || 0),
        Header: () => <span>LP Clicks</span>,
        Footer: footer => sum((offers || []).map(row => row.lp_clicks)),
      },
      {
        id: 'prelp_clicks',
        title: 'Pre LP clicks',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.prelp_clicks',
        description: 'Pre LP clicks',
        Cell: row => (row.value || 0),
        Header: () => <span>Pre LP clicks</span>,
        Footer: footer => sum((offers || []).map(row => row.prelp_clicks)),
      },
      {
        id: 'prelp_click_ctr',
        title: 'Pre-LP click CTR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.prelp_click_ctr',
        description: 'Pre-LP click CTR',
        Cell: row => numeral(row.original.stat.prelp_clicks && row.original.stat.clicks !== 0 ? row.original.stat.prelp_clicks / row.original.stat.clicks : 0).format('0.00%'),
        Header: () => <span>Pre-LP click CTR</span>,
        Footer: footer => {
          const prelp_clicks = sum((offers || []).map(row => row.stat.prelp_clicks))
          const clicks = sum((offers || []).map(row => row.stat.clicks))
          return numeral(clicks !== 0 ? prelp_clicks / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'lp_ctr',
        title: 'LP CTR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.lp_ctr',
        description: 'LP Clicks to LP views ratio',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>LP CTR</span>,
        Footer: footer => {
          const lp_clicks = sum((offers || []).map(row => row.stat.lp_clicks))
          const lp_views = sum((offers || []).map(row => row.stat.lp_views))
          return numeral(lp_views !== 0 ? lp_clicks / lp_views : 0).format('0.00%')
        },
      },
      {
        id: 'lp_views_cr',
        title: 'LP Views CR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.lp_views_cr',
        description: 'LP Views CR',
        Cell: row => numeral(row.original.stat.conversions && row.original.stat.lp_views !== 0 ? row.original.stat.conversions / row.original.stat.lp_views : 0).format('0.00%'),
        Header: () => <span>LP Views CR</span>,
        Footer: footer => {
          const conversions = sum((offers || []).map(row => row.stat.conversions))
          const lp_views = sum((offers || []).map(row => row.stat.lp_views))
          return numeral(lp_views !== 0 ? conversions / lp_views : 0).format('0.00%')
        },
      },
      {
        id: 'lp_clicks_cr',
        title: 'LP Clicks CR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.lp_clicks_cr',
        description: 'LP Clicks CR',
        Cell: row => numeral(row.original.stat.conversions && row.original.stat.lp_clicks !== 0 ? row.original.stat.conversions / row.original.stat.lp_clicks : 0).format('0.00%'),
        Header: () => <span>LP Clicks CR</span>,
        Footer: footer => {
          const conversions = sum((offers || []).map(row => row.stat.conversions))
          const lp_clicks = sum((offers || []).map(row => row.stat.lp_clicks))
          return numeral(lp_clicks !== 0 ? conversions / lp_clicks : 0).format('0.00%')
        },
      },
      {
        id: 'lp_click_ctr',
        title: 'LP Click CTR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.lp_click_ctr',
        description: 'Clicks to LP clicks Ratio',
        Cell: row => numeral((row.original.stat.lp_clicks && row.original.stat.clicks !== 0) ? row.original.stat.lp_clicks/row.original.stat.clicks : 0).format('0.00%'),
        Header: () => <span>LP Click CTR</span>,
        Footer: footer => {
          const lp_clicks = sum((offers || []).map(row => row.stat.lp_clicks))
          const clicks    = sum((offers || []).map(row => row.stat.clicks))
          return numeral(clicks !== 0 ? lp_clicks / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'conversions',
        title: renderConvTypeTitle('conv_default_type','conv_', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.conversions',
        Cell: row => (row.value || 0),
        Header: () => <span>{renderConvTypeTitle('conv_default_type','conv_', user)}</span>,
        Footer: footer => sum((offers || []).map(row => row.conversions)),
      },
      {
        id: 'total_conversions_cr',
        className: '',
        title: 'Total Actions CR',
        minWidth: 120,
        width: 120,
        accessor: 'stat.total_conversions_cr',
        Cell: row => numeral(row.original.stat.total_conversions && row.original.stat.clicks !== 0 ? row.original.stat.total_conversions / row.original.stat.clicks : 0).format('0.00%'),
        Header: () => <span>Total Actions CR</span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.total_conversions))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'total_conversions',
        title: 'Total Actions',
        className: '',
        minWidth: 150,
        width: 150,
        accessor: 'stat.total_conversions',
        Cell: row => (row.value || 0),
        Header: () => <span>Total Actions</span>,
        Footer: footer => sum((offers || []).map(row => row.total_conversions)),
      },
      {
        id: 'cr',
        title: 'CR',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.cr',
        description: 'Conversion rate (clicks to conversions)',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>CR</span>,
        Footer: footer => {
          const conversions = sum((offers || []).map(row => row.stat.conversions))
          const clicks = sum((offers || []).map(row => row.stat.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'tr',
        title: 'TR',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.tr',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>TR</span>,
        Footer: footer => {
          const transactions = sum((offers || []).map(row => row.stat.transactions))
          const clicks = sum((offers || []).map(row => row.stat.clicks))
          return numeral(clicks !== 0 ? transactions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'approved',
        title: 'Approved',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.approved',
        Header: () => <span>Approved</span>,
        Cell: row => (row.value || 0),
        Footer: footer => sum((offers || []).map(row => row.approved)),
      },
      {
        id: 'approved_rate',
        title: 'Approved rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.approved_rate',
        Cell: row => numeral(row.original.stat.approved ? row.original.stat.approved/row.original.stat.clicks : 0).format('0.00%'),
        Header: () => <span>Approved rate</span>,
        Footer: footer => {
          const approved = sum((offers || []).map(row => row.stat.approved))
          const clicks = sum((offers || []).map(row => row.stat.clicks))
          return numeral(clicks !== 0 ? approved / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'pending',
        title: 'Pending',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.pending',
        Header: () => <span>Pending</span>,
        Cell: row => (row.value || 0),
        Footer: footer => sum((offers || []).map(row => row.pending)),
      },
      {
        id: 'pending_rate',
        title: 'Pending rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.pending_rate',
        Cell: row => numeral(row.original.stat.pending ? row.original.stat.pending/row.original.stat.clicks : 0).format('0.00%'),
        Header: () => <span>Pending rate</span>,
        Footer: footer => {
          const pending = sum((offers || []).map(row => row.stat.pending))
          const clicks = sum((offers || []).map(row => row.stat.clicks))
          return numeral(clicks !== 0 ? pending / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'declined',
        title: 'Declined',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.declined',
        Cell: row => (row.value || 0),
        Header: () => <span>Declined</span>,
        Footer: footer => sum((offers || []).map(row => row.declined)),
      },
      {
        id: 'declined_rate',
        title: 'Declined rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.declined_rate',
        Cell: row => numeral(row.original.stat.declined ? row.original.stat.declined/row.original.stat.clicks : 0).format('0.00%'),
        Header: () => <span>Declined rate</span>,
        Footer: footer => {
          const declined = sum((offers || []).map(row => row.stat.declined))
          const clicks = sum((offers || []).map(row => row.stat.clicks))
          return numeral(clicks !== 0 ? declined / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'other',
        title: 'Other',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.other',
        Header: () => <span>Other</span>,
        Cell: row => (row.value || 0),
        Footer: footer => sum((offers || []).map(row => row.other)),
      },
      {
        id: 'other_rate',
        title: 'Other rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.other_rate',
        Cell: row => numeral(row.original.stat.other ? row.original.stat.other/row.original.stat.clicks : 0).format('0.00%'),
        Header: () => <span>Other rate</span>,
        Footer: footer => {
          const other = sum((offers || []).map(row => row.stat.other));
          const clicks = sum((offers || []).map(row => row.stat.clicks));
          return numeral(clicks !== 0 ? other / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'transactions',
        title: 'Transactions',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.transactions',
        Cell: row => (row.value || 0),
        Header: () => <span>Transactions</span>,
        Footer: footer => sum((offers || []).map(row => row.transactions)),
      },
      {
        id: 'revenue',
        title: renderRevenueTypeTitle('revenue_default_type','revenue_', user),
        className: 'bolder',
        minWidth: 180,
        width: 180,
        accessor: 'stat.revenue',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>{renderRevenueTypeTitle('revenue_default_type','revenue_', user)}</span>,
        Footer: footer => numeral(sum((offers || []).map(row => row.revenue))).format('$0.00[00]'),
      },
      {
        id: 'total_revenue',
        title: 'Total Revenue',
        className: 'bolder',
        minWidth: 180,
        width: 180,
        accessor: 'stat.total_revenue',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>Total Revenue</span>,
        Footer: footer => numeral(sum((offers || []).map(row => row.total_revenue))).format('$0.00[00]'),
      },
      {
        id: 'cost',
        title: 'Cost',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.cost',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>Cost</span>,
        Footer: footer => numeral(sum((offers || []).map(row => row.cost))).format('$0.00[00]'),
      },
      // {
      //   id: 'pubrevenue',
      //   title: 'Comission',
      //   className: '',
      //   minWidth: 120,
      //   width: 120,
      //   accessor: 'stat.pubrevenue',
      //   Cell: row => numeral(row.value).format('$0.00[00]'),
      //   Header: () => <span>Comission</span>,
      //   Footer: footer => numeral(sum((offers || []).map(row => row.pubrevenue))).format('$0.00[00]'),
      // },
      {
        id: 'profit',
        title: 'Profit',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.profit',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>Profit</span>,
        Footer: footer => numeral(sum((offers || []).map(row => row.profit))).format('$0.00[00]'),
      },
      {
        id: 'roi',
        title: 'ROI',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.roi',
        description: 'Return on Investment',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>ROI</span>,
        Footer: footer => {
          const profit = sum((offers || []).map(row => row.stat.profit))
          const cost   = sum((offers || []).map(row => row.stat.cost))
          return numeral(cost !== 0 ? profit / cost : 0).format('0.00%')
        },
      },
      {
        id: 'cpa',
        title: 'CPA',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.cpa',
        description: 'Cost per Action',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>CPA</span>,
        Footer: footer => {
          const conversions = sum((offers || []).map(row => row.stat.conversions))
          const cost = sum((offers || []).map(row => row.stat.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('$0.00[00]')
        },
      },
      {
        id: 'cpc',
        title: 'CPC',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.cpc',
        description: 'Cost per Click',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>CPC</span>,
        Footer: footer => {
          const clicks = sum((offers || []).map(row => row.stat.clicks))
          const cost   = sum((offers || []).map(row => row.stat.cost))
          return numeral(clicks !== 0 ? cost / clicks : 0).format('$0.00[00]')
        },
      },
      {
        id: 'cpt',
        title: 'CPT',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'stat.cpt',
        description: 'Cost per Transaction',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>CPT</span>,
        Footer: footer => {
          const transactions = sum((offers || []).map(row => row.stat.transactions))
          const cost = sum((offers || []).map(row => row.stat.cost))
          return numeral(transactions !== 0 ? cost / transactions : 0).format('$0.00[00]')
        },
      },
      {
        id: 'epc',
        title: 'EPC',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'epc',
        description: 'Earning per click',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>EPC</span>,
        Footer: footer => {
          const revenue = sum((offers || []).map(row => row.stat.revenue))
          const clicks   = sum((offers || []).map(row => row.stat.clicks))
          return numeral(clicks !== 0 ? revenue / clicks : 0).format('$0.00[00]')
        },
      },
      {
        id: 'convtype1',
        title: renderConvTypeTitle('convtype1','conv', user),
        accessor: 'stat.convtype1',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype1','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype1)),
      },
      {
        id: 'convtype2',
        title: renderConvTypeTitle('convtype2','conv', user),
        className: '',
        accessor: 'stat.convtype2',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype2','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype2)),
      },
      {
        id: 'convtype3',
        title: renderConvTypeTitle('convtype3','conv', user),
        className: '',
        accessor: 'stat.convtype3',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype3','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype3)),
      },
      {
        id: 'convtype4',
        title: renderConvTypeTitle('convtype4','conv', user),
        className: '',
        accessor: 'stat.convtype4',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype4','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype4)),
      },
      {
        id: 'convtype5',
        title: renderConvTypeTitle('convtype5','conv', user),
        className: '',
        accessor: 'stat.convtype5',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype5','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype5)),
      },
      {
        id: 'convtype6',
        title: renderConvTypeTitle('convtype6','conv', user),
        className: '',
        accessor: 'stat.convtype6',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype6','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype6)),
      },
      {
        id: 'convtype7',
        title: renderConvTypeTitle('convtype7','conv', user),
        className: '',
        accessor: 'stat.convtype7',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype7','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype7)),
      },
      {
        id: 'convtype8',
        title: renderConvTypeTitle('convtype8','conv', user),
        className: '',
        accessor: 'stat.convtype8',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype8','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype8)),
      },
      {
        id: 'convtype9',
        title: renderConvTypeTitle('convtype9','conv', user),
        className: '',
        accessor: 'stat.convtype9',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype9','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype9)),
      },
      {
        id: 'convtype10',
        title: renderConvTypeTitle('convtype10','conv', user),
        className: '',
        accessor: 'stat.convtype10',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype10','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype10)),
      },
      {
        id: 'convtype11',
        title: renderConvTypeTitle('convtype11','conv', user),
        className: '',
        accessor: 'stat.convtype11',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype11','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype11)),
      },
      {
        id: 'convtype12',
        title: renderConvTypeTitle('convtype12','conv', user),
        className: '',
        accessor: 'stat.convtype12',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype12','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype12)),
      },
      {
        id: 'convtype13',
        title: renderConvTypeTitle('convtype13','conv', user),
        className: '',
        accessor: 'stat.convtype13',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype13','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype13)),
      },
      {
        id: 'convtype14',
        title: renderConvTypeTitle('convtype14','conv', user),
        className: '',
        accessor: 'stat.convtype14',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype14','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype14)),
      },
      {
        id: 'convtype15',
        title: renderConvTypeTitle('convtype15','conv', user),
        className: '',
        accessor: 'stat.convtype15',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype15','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype15)),
      },
      {
        id: 'convtype16',
        title: renderConvTypeTitle('convtype16','conv', user),
        className: '',
        accessor: 'stat.convtype16',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype16','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype16)),
      },
      {
        id: 'convtype17',
        title: renderConvTypeTitle('convtype17','conv', user),
        className: '',
        accessor: 'stat.convtype17',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype17','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype17)),
      },
      {
        id: 'convtype18',
        title: renderConvTypeTitle('convtype18','conv', user),
        className: '',
        accessor: 'stat.convtype18',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype18','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype18)),
      },
      {
        id: 'convtype19',
        title: renderConvTypeTitle('convtype19','conv', user),
        className: '',
        accessor: 'stat.convtype19',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype19','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype19)),
      },
      {
        id: 'convtype20',
        title: renderConvTypeTitle('convtype20','conv', user),
        className: '',
        accessor: 'stat.convtype20',
        minWidth: 120,
        width: 120,
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype20','conv', user)}
            </span>,
        Cell: row => (row.value || 0),
        Footer: t => sum((offers || []).map(r => r.convtype20)),
      },
      {
        id: 'convratetype1',
        title: renderConvTypeRateTitle('convtype1','conv', user),
        className: '',
        accessor: 'stat.convratetype1',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype1 && row.original.stat.clicks !== 0 ? row.original.stat.convtype1 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype1','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype1))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype2',
        title: renderConvTypeRateTitle('convtype2','conv', user),
        className: '',
        accessor: 'stat.convratetype2',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype2 && row.original.stat.clicks !== 0 ? row.original.stat.convtype2 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype2','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype2))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype3',
        title: renderConvTypeRateTitle('convtype3','conv', user),
        className: '',
        accessor: 'stat.convratetype3',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype3 && row.original.stat.clicks !== 0 ? row.original.stat.convtype3 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype3','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype3))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype4',
        title: renderConvTypeRateTitle('convtype4','conv', user),
        className: '',
        accessor: 'stat.convratetype4',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype4 && row.original.stat.clicks !== 0 ? row.original.stat.convtype4 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype4','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype4))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype5',
        title: renderConvTypeRateTitle('convtype5','conv', user),
        className: '',
        accessor: 'stat.convratetype5',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype5 && row.original.stat.clicks !== 0 ? row.original.stat.convtype5 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype5','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype5))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype6',
        title: renderConvTypeRateTitle('convtype6','conv', user),
        className: '',
        accessor: 'stat.convratetype6',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype6 && row.original.stat.clicks !== 0 ? row.original.stat.convtype6 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype6','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype6))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype7',
        title: renderConvTypeRateTitle('convtype7','conv', user),
        className: '',
        accessor: 'stat.convratetype7',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype7 && row.original.stat.clicks !== 0 ? row.original.stat.convtype7 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype7','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype7))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype8',
        title: renderConvTypeRateTitle('convtype8','conv', user),
        className: '',
        accessor: 'stat.convratetype8',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype8 && row.original.stat.clicks !== 0 ? row.original.stat.convtype8 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype8','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype8))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype9',
        title: renderConvTypeRateTitle('convtype9','conv', user),
        className: '',
        accessor: 'stat.convratetype9',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype9 && row.original.stat.clicks !== 0 ? row.original.stat.convtype9 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype9','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype9))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype10',
        title: renderConvTypeRateTitle('convtype10','conv', user),
        className: '',
        accessor: 'stat.convratetype10',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype10 && row.original.stat.clicks !== 0 ? row.original.stat.convtype10 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype10','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype10))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype11',
        title: renderConvTypeRateTitle('convtype11','conv', user),
        className: '',
        accessor: 'stat.convratetype11',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype11 && row.original.stat.clicks !== 0 ? row.original.stat.convtype11 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype11','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype11))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype12',
        title: renderConvTypeRateTitle('convtype12','conv', user),
        className: '',
        accessor: 'stat.convratetype12',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype12 && row.original.stat.clicks !== 0 ? row.original.stat.convtype12 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype12','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype12))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype13',
        title: renderConvTypeRateTitle('convtype13','conv', user),
        className: '',
        accessor: 'stat.convratetype13',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype13 && row.original.stat.clicks !== 0 ? row.original.stat.convtype13 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype13','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype13))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype14',
        title: renderConvTypeRateTitle('convtype14','conv', user),
        className: '',
        accessor: 'stat.convratetype14',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype14 && row.original.stat.clicks !== 0 ? row.original.stat.convtype14 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype14','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype14))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype15',
        title: renderConvTypeRateTitle('convtype15','conv', user),
        className: '',
        accessor: 'stat.convratetype15',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype15 && row.original.stat.clicks !== 0 ? row.original.stat.convtype15 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype15','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype15))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype16',
        title: renderConvTypeRateTitle('convtype16','conv', user),
        className: '',
        accessor: 'stat.convratetype16',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype16 && row.original.stat.clicks !== 0 ? row.original.stat.convtype16 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype16','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype16))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype17',
        title: renderConvTypeRateTitle('convtype17','conv', user),
        className: '',
        accessor: 'stat.convratetype17',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype17 && row.original.stat.clicks !== 0 ? row.original.stat.convtype17 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype17','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype17))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype18',
        title: renderConvTypeRateTitle('convtype18','conv', user),
        className: '',
        accessor: 'stat.convratetype18',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype18 && row.original.stat.clicks !== 0 ? row.original.stat.convtype18 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype18','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype18))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype19',
        title: renderConvTypeRateTitle('convtype19','conv', user),
        className: '',
        accessor: 'stat.convratetype19',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype19 && row.original.stat.clicks !== 0 ? row.original.stat.convtype19 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype19','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype19))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'convratetype20',
        title: renderConvTypeRateTitle('convtype20','conv', user),
        className: '',
        accessor: 'stat.convratetype20',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.convtype20 && row.original.stat.clicks !== 0 ? row.original.stat.convtype20 / row.original.stat.clicks : 0).format('0.00%'),
        Header: () =>
          <span>
              {renderConvTypeRateTitle('convtype20','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype20))
          const clicks = sum(t.data.map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
      },
      {
        id: 'revenuetype1',
        title: renderRevenueTypeTitle('revenuetype1','revenue', user),
        className: '',
        accessor: 'stat.revenuetype1',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype1','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype1))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype2',
        title: renderRevenueTypeTitle('revenuetype2','revenue', user),
        className: '',
        accessor: 'stat.revenuetype2',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype2','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype2))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype3',
        title: renderRevenueTypeTitle('revenuetype3','revenue', user),
        className: '',
        accessor: 'stat.revenuetype3',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype3','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype3))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype4',
        title: renderRevenueTypeTitle('revenuetype4','revenue', user),
        className: '',
        accessor: 'stat.revenuetype4',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype4','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype4))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype5',
        title: renderRevenueTypeTitle('revenuetype5','revenue', user),
        className: '',
        accessor: 'stat.revenuetype5',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype5','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype5))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype6',
        title: renderRevenueTypeTitle('revenuetype6','revenue', user),
        className: '',
        accessor: 'stat.revenuetype6',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype6','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype6))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype7',
        title: renderRevenueTypeTitle('revenuetype7','revenue', user),
        className: '',
        accessor: 'stat.revenuetype7',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype7','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype7))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype8',
        title: renderRevenueTypeTitle('revenuetype8','revenue', user),
        className: '',
        accessor: 'stat.revenuetype8',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype8','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype8))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype9',
        title: renderRevenueTypeTitle('revenuetype9','revenue', user),
        className: '',
        accessor: 'stat.revenuetype9',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype9','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype9))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype10',
        title: renderRevenueTypeTitle('revenuetype10','revenue', user),
        className: '',
        accessor: 'stat.revenuetype10',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype10','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype10))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype11',
        title: renderRevenueTypeTitle('revenuetype11','revenue', user),
        className: '',
        accessor: 'stat.revenuetype11',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype11','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype11))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype12',
        title: renderRevenueTypeTitle('revenuetype12','revenue', user),
        className: '',
        accessor: 'stat.revenuetype12',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype12','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype12))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype13',
        title: renderRevenueTypeTitle('revenuetype13','revenue', user),
        className: '',
        accessor: 'stat.revenuetype13',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype13','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype13))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype14',
        title: renderRevenueTypeTitle('revenuetype14','revenue', user),
        className: '',
        accessor: 'stat.revenuetype14',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype14','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype14))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype15',
        title: renderRevenueTypeTitle('revenuetype15','revenue', user),
        className: '',
        accessor: 'stat.revenuetype15',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype15','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype15))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype16',
        title: renderRevenueTypeTitle('revenuetype16','revenue', user),
        className: '',
        accessor: 'stat.revenuetype16',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype16','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype16))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype17',
        title: renderRevenueTypeTitle('revenuetype17','revenue', user),
        className: '',
        accessor: 'stat.revenuetype17',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype17','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype17))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype18',
        title: renderRevenueTypeTitle('revenuetype18','revenue', user),
        className: '',
        accessor: 'stat.revenuetype18',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype18','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype18))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype19',
        title: renderRevenueTypeTitle('revenuetype19','revenue', user),
        className: '',
        accessor: 'stat.revenuetype19',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype19','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype19))).format('$0.00[00]'),
      },
      {
        id: 'revenuetype20',
        title: renderRevenueTypeTitle('revenuetype20','revenue', user),
        className: '',
        accessor: 'stat.revenuetype20',
        minWidth: 180,
        width: 180,
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype20','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype20))).format('$0.00[00]'),
      },
      {
        id: 'convcpatype1',
        title: renderConvTypeCpaTitle('convtype1','conv', user),
        className: '',
        accessor: 'stat.convcpatype1',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype1 !== 0 ? row.original.stat.cost / row.original.stat.convtype1 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype1','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype1))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype2',
        title: renderConvTypeCpaTitle('convtype2','conv', user),
        className: '',
        accessor: 'stat.convcpatype2',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype2 !== 0 ? row.original.stat.cost / row.original.stat.convtype2 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype2','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype2))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype3',
        title: renderConvTypeCpaTitle('convtype3','conv', user),
        className: '',
        accessor: 'stat.convcpatype3',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype3 !== 0 ? row.original.stat.cost / row.original.stat.convtype3 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype3','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype3))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype4',
        title: renderConvTypeCpaTitle('convtype4','conv', user),
        className: '',
        accessor: 'stat.convcpatype4',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype4 !== 0 ? row.original.stat.cost / row.original.stat.convtype4 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype4','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype4))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype5',
        title: renderConvTypeCpaTitle('convtype5','conv', user),
        className: '',
        accessor: 'stat.convcpatype5',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype5 !== 0 ? row.original.stat.cost / row.original.stat.convtype5 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype5','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype5))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype6',
        title: renderConvTypeCpaTitle('convtype6','conv', user),
        className: '',
        accessor: 'stat.convcpatype6',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype6 !== 0 ? row.original.stat.cost / row.original.stat.convtype6 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype6','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype6))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype7',
        title: renderConvTypeCpaTitle('convtype7','conv', user),
        className: '',
        accessor: 'stat.convcpatype7',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype7 !== 0 ? row.original.stat.cost / row.original.stat.convtype7 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype7','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype7))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype8',
        title: renderConvTypeCpaTitle('convtype8','conv', user),
        className: '',
        accessor: 'stat.convcpatype8',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype8 !== 0 ? row.original.stat.cost / row.original.stat.convtype8 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype8','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype8))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype9',
        title: renderConvTypeCpaTitle('convtype9','conv', user),
        className: '',
        accessor: 'stat.convcpatype9',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype9 !== 0 ? row.original.stat.cost / row.original.stat.convtype9 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype9','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype9))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype10',
        title: renderConvTypeCpaTitle('convtype10','conv', user),
        className: '',
        accessor: 'stat.convcpatype10',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype10 !== 0 ? row.original.stat.cost / row.original.stat.convtype10 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype10','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype10))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype11',
        title: renderConvTypeCpaTitle('convtype11','conv', user),
        className: '',
        accessor: 'stat.convcpatype11',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype11 !== 0 ? row.original.stat.cost / row.original.stat.convtype11 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype11','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype11))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype12',
        title: renderConvTypeCpaTitle('convtype12','conv', user),
        className: '',
        accessor: 'stat.convcpatype12',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype12 !== 0 ? row.original.stat.cost / row.original.stat.convtype12 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype12','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype12))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype13',
        title: renderConvTypeCpaTitle('convtype13','conv', user),
        className: '',
        accessor: 'stat.convcpatype13',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype13 !== 0 ? row.original.stat.cost / row.original.stat.convtype13 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype13','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype13))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype14',
        title: renderConvTypeCpaTitle('convtype14','conv', user),
        className: '',
        accessor: 'stat.convcpatype14',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype14 !== 0 ? row.original.stat.cost / row.original.stat.convtype14 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype14','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype14))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype15',
        title: renderConvTypeCpaTitle('convtype15','conv', user),
        className: '',
        accessor: 'stat.convcpatype15',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype15 !== 0 ? row.original.stat.cost / row.original.stat.convtype15 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype15','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype15))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype16',
        title: renderConvTypeCpaTitle('convtype16','conv', user),
        className: '',
        accessor: 'stat.convcpatype16',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype16 !== 0 ? row.original.stat.cost / row.original.stat.convtype16 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype16','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype16))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype17',
        title: renderConvTypeCpaTitle('convtype17','conv', user),
        className: '',
        accessor: 'stat.convcpatype17',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype17 !== 0 ? row.original.stat.cost / row.original.stat.convtype17 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype17','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype17))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype18',
        title: renderConvTypeCpaTitle('convtype18','conv', user),
        className: '',
        accessor: 'stat.convcpatype18',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype18 !== 0 ? row.original.stat.cost / row.original.stat.convtype18 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype18','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype18))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype19',
        title: renderConvTypeCpaTitle('convtype19','conv', user),
        className: '',
        accessor: 'stat.convcpatype19',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype19 !== 0 ? row.original.stat.cost / row.original.stat.convtype19 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype19','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype19))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
      },
      {
        id: 'convcpatype20',
        title: renderConvTypeCpaTitle('convtype20','conv', user),
        className: '',
        accessor: 'stat.convcpatype20',
        minWidth: 120,
        width: 120,
        Cell: row => numeral(row.original.stat.cost && row.original.stat.convtype20 !== 0 ? row.original.stat.cost / row.original.stat.convtype20 : 0).format('0.00[00]'),
        Header: () =>
          <span>
              {renderConvTypeCpaTitle('convtype20','conv', user)}
            </span>,
        Footer: t => {
          const conversions = sum(t.data.map(r => r.convtype20))
          const cost = sum(t.data.map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        ...renderStatConvTypeAov(user, '', CONVTYPE_COUNT),
      },
    ]
  }

  onChangePage = (pageOfItems) => {
    const { itemsToShow } = this.state;
    const { offers } = this.props;
    if (pageOfItems) {
      const rowsToShow = this.filterRows(offers.items, pageOfItems, itemsToShow);
      this.setState({ rowsToShow, pageOfItems, rows: offers.items });
    }
  };

  prepareTotalDataRow = (offers, heads) => {
    const { statHeads } = this.state;
    let newOffersData = [...offers];
    let totalKey = heads[0].key;
    let totalData = {};
    totalData.stat = {}
    totalData[totalKey] = TOTAL_TEXT;
    statHeads.map((columnKey, i) => {
      let stat = totalData.stat
      stat[columnKey.key] = offers.length !== 0 ? offers.reduce((accumulator, currentValue) => {
        const value = currentValue.stat[columnKey.key] ? currentValue.stat[columnKey.key] : 0
        return (accumulator + value)
      }, 0) : 0;
      switch (columnKey.key) {
        case 'ctr':
          stat.ctr = numeral(stat.impressions !== 0 ? stat.clicks / stat.impressions : 0);
          break;
        case 'lp_ctr':
          stat.lp_ctr = numeral(stat.lp_views !== 0 ? stat.lp_clicks / stat.lp_views : 0);
          break;
        case 'lp_click_ctr':
          stat.lp_click_ctr = numeral(stat.clicks !== 0 ? stat.lp_clicks / stat.clicks : 0);
          break;
        case 'cr':
          stat.cr = numeral(stat.clicks !== 0 ? stat.conversions / stat.clicks : 0);
          break;
        case 'approved_rate':
          stat.approved_rate = numeral(stat.clicks !== 0 ? stat.approved / stat.clicks : 0);
          break;
        case 'pending_rate':
          stat.pending_rate = numeral(stat.clicks !== 0 ? stat.pending / stat.clicks : 0);
          break;
        case 'declined_rate':
          stat.declined_rate = numeral(stat.clicks !== 0 ? stat.declined / stat.clicks : 0);
          break;
        case 'other_rate':
          stat.other_rate = numeral(stat.clicks !== 0 ? stat.other / stat.clicks : 0);
          break;
        case 'roi':
          stat.roi = numeral(stat.cost !== 0 ? stat.profit / stat.cost : 0);
          break;
        case 'cpa':
          stat.cpa = numeral(stat.conversions !== 0 ? stat.cost / stat.conversions : 0);
          break;
        case 'cpc':
          stat.cpc = numeral(stat.clicks !== 0 ? stat.cost / stat.clicks : 0);
          break;
        case 'cpt':
          stat.cpt = numeral(stat.transactions !== 0 ? stat.cost / stat.transactions : 0);
          break;
        case 'epc':
          stat.epc = numeral(stat.clicks !== 0 ? stat.revenue / stat.clicks : 0);
          break;
        default:
      }
    });
    newOffersData.push(totalData)
    return [...newOffersData]
  };

  filterRows = (originalRows, pageNumber, rowsOnPage) => {
    const rowsFrom = rowsOnPage * (pageNumber - 1);
    const rowsTo = rowsFrom + rowsOnPage;
    const data = originalRows.slice(rowsFrom, rowsTo)
    return data;
  };

  renderRowColor = (row) => {
    let rowColor = '';
    const index  = row.index|| row.viewIndex
    if (index % 2 === 0) {
      rowColor = 'custom-odd'
    } else {
      rowColor = 'custom-even'
    }
    return rowColor
  };

  render () {
    const { pageOfItems, itemsToShow, rowsToShow, rows, heads } = this.state;
    const { loading, loaded } = this.props;
    const itemsToRender = this.prepareCompanyData(rowsToShow);
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <OffersFilters
              handleApplyFilters={this.handleApplyFilters}
            />
            <LoadableContainer
              loading={loading}
              loaded={loaded}
              style={{
                position: 'relative',
                width: '100%',
                maxHeight: '350px'
              }}
            >
              {itemsToRender &&
              <Fragment>
                <Pagination
                  className='offer-pagination-top'
                  itemsCount={rows.length}
                  itemsToShow={itemsToShow}
                  pageOfItems={pageOfItems}
                  onChangePage={this.onChangePage}
                />
                <CustomizableTable
                  className='custom-data-offer-table'
                  columns={heads}
                  data={rowsToShow}
                  style={{ maxHeight: "100%", borderLeft: 0, borderRight: 0, borderBottom: 0, width: '100%' }}
                  showPagination={false}
                  pageSize={rowsToShow.length}
                  loading={loading}
                  getTrProps={(state, row, column, instance) => ({
                    className: (row && row.original && this.renderRowColor(row)),
                  })}
                />
                <Pagination
                  itemsCount={rows.length}
                  itemsToShow={itemsToShow}
                  pageOfItems={pageOfItems}
                  onChangePage={this.onChangePage}
                />
              </Fragment>
              }
            </LoadableContainer>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  offers: state.offers,
  loading: state.offers.loading,
  loaded: state.offers.loaded,
});

export default connect(
  mapStateToProps,
)(OffersTable)
