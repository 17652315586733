import React, { Component, Fragment } from 'react';
import moment from 'moment'
import { Col } from 'reactstrap';
import store from "../../../../containers/App/store";
import { Field, reduxForm } from "redux-form";
import renderIntervalDatePickerField from '../DatePicker'
import ToggleButtonGroup from "./ToggleButtonGroup";
import { DASHBOARD_BY_DAY, DASHBOARD_BY_OFFER } from '../../../../constants'
import { loadDashboardReportAction } from "../../../../redux/actions/dashboardsActions";
import { loadOffersAction } from "../../../../redux/actions/offersActions";
import Select from 'react-select';

class DashboardFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scope: props.scope,
      offersOptions: [],
      filteredBy: null,
      filters: store.getState().dashboards[`${props.scope}_filters`],
    };
  }

  componentDidMount() {
    store.dispatch(loadOffersAction).then((data) => {
      const offersData = data || []
      let offersOptions = undefined
      if (offersData) {
        offersOptions = offersData.map(offer => ({ value: offer.id, label: offer.title }))
      }
      this.setState({ offersOptions });
    })
  }

  handleSubmit = () => {
    const { currentTable } = this.props;
    const { filters, scope } = this.state;
    if (currentTable === DASHBOARD_BY_DAY) {
      store.dispatch(loadDashboardReportAction(scope, {  ...filters }))
    } else if (currentTable === DASHBOARD_BY_OFFER) {
      store.dispatch(loadDashboardReportAction(scope, {  ...filters }));
      store.dispatch(loadDashboardReportAction(scope, {  ...filters }, false))
    }
  };

  handleChange = (event) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        date_from: moment(event.start),
        date_to: moment(event.end),
      }
    })
  }

  toggleCurrentTableKey = (currentTableKey) => {
    const { toggleAction } = this.props
    const { filters } = this.state
    if (toggleAction) {
      toggleAction(currentTableKey, filters)
    }
  }

  handleChangeSelect = (event) => {
    const { filters, offersOptions } = this.state;
    if (event) {
      filters.offer_ids = event.value
      offersOptions.map(option => option.isDisabled = option.value === event.value)
    } else {
      offersOptions.map(option => option.isDisabled = false);
      delete filters.offer_ids
    }
    this.setState({
      offersOptions,
      filters: {
        ...filters,
      },
      filteredBy: event,
    });
  };

  render() {
    const { toggleAction } = this.props
    const hideCampaigns = JSON.parse(localStorage.getItem('settings')) && JSON.parse(localStorage.getItem('settings')).domain_id == '624de8e02744a700012a5601' ? true : false
    const { filters, offersOptions, filteredBy } = this.state;
    return (
      <Fragment>
        <form className="form" onSubmit={this.handleSubmit}>
          <div className="form__form-group mb-0">
            <div className="form__form-group-field">
              {toggleAction &&
              <ToggleButtonGroup
                toggleAction={this.toggleCurrentTableKey}
              />}
              <Col md={3} className='custom-date-picker'>
                <Field
                  name="interval_date"
                  filters={filters}
                  component={renderIntervalDatePickerField}
                  onChange={this.handleChange}
                />
              </Col>
              {!hideCampaigns &&
                <Col md={3} lg={3} sm={3}>
                  <div
                    className="filter__by-offer"
                  >
                    <span className="form__form-group-label label">Filter by</span>
                    <div className="form__form-group-field">
                      <Select
                        style={{
                          minWidth: '100%',
                        }}
                        isMulti={false}
                        name='select'
                        value={filteredBy}
                        onChange={this.handleChangeSelect}
                        options={offersOptions}
                        isClearable={true}
                        closeOnSelect={false}
                        removeSelected={false}
                        className="react-select"
                        placeholder='Choose campaign'
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                </Col>
              }
              <Col md={2}>
                <div
                  style={{
                    width: 90,
                  }}
                >
                  <div
                    style={{
                      height: 42,
                      lineHeight: '20px'
                    }}
                    onClick={this.handleSubmit}
                    className="btn btn-primary account__btn"
                  >
                    Apply
                  </div>
                </div>
              </Col>

            </div>


          </div>
        </form>
      </Fragment>
    )
  }

}

DashboardFilters.defaultProps = {
  toggleAction: undefined,
  currentTable: DASHBOARD_BY_DAY,
}

export default reduxForm({
  form: 'date_picker_form',
})(DashboardFilters)
