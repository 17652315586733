import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import store from '../containers/App/store'
import { signOutAction } from './../redux/actions/usersActions'

const isLoggedIn = () => {
    return !!store.getState().users.currentUser
}

// Routes with auth checking`
export class PublicRoute extends React.Component {

    render() {
        return (
            <Route {...this.props} />
        )
    }
}

export function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => isLoggedIn()
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }
        />
    )
}
