/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import numeral from 'numeral'

import store from "../../../../containers/App/store";
import { LoadableContainer } from '../../../../widgets'
import ReportsCharts from "../reportsCharts/ReportsCharts";
import { capitalize, sum, sumHiddenColumn } from '../../../../utils/Helpers';
import { renderConvTypeAov } from '../../../../utils/columns';
import {
  getSavedOrderOfColumns,
  renderRevenueTypeTitle,
  renderConvTypeTitle,
  renderConvTypeRateTitle,
  renderConvTypeCpaTitle,
} from '../../../../utils';
import { loadReportChartAction } from '../../../../redux/actions/reportActions'
import OfferFilters from './OfferFilters';
import CustomizableTable from '../../../../common/table/customizableTable';

import { NA_VALUE, DEFAULT_GROUPING_BY_DATE } from '../../../../constants';
import { DASHBOARD_EXCLUDE_METRICS, METRICS_DATA } from '../../../../constants/dashboard'
import { CONVTYPE_COUNT } from '../../../../constants/columns';

class OfferReportTable extends Component {
  constructor (props) {
    super(props);
    this.state = {
      scope: props.scope,
      heads: [],
      defaultHeads: [],
      defaultHeadColumn: {
        title: 'Date',
        Header: () => <span>Date</span>,
        accessor: 'date',
        minWidth: 170,
        width: 170,
        PivotValue: item => <span>{(item.value !== '') ? item.value : NA_VALUE}</span>,
        value: item => <span>{(item.value !== '') ? item.value : NA_VALUE}</span>,
        Footer: <span>Total:</span>,
        Expander: () => null,
      },
      groupingHeadColumn: [],
      reportData: [],
      isChartVisible: true,
      isChartNeed: true,
      columnsRenderer: this._renderOffersColumns,
      defaultChartsReportsMetrics: [
        'clicks',
        'conversions',
        // 'unique_clicks',
        // 'cost',
      ],
    }

  }

  static getDerivedStateFromProps (props, state) {
    const usersSettings = props.users.settings
    const tables = usersSettings && usersSettings.settings ? usersSettings.settings : []
    const sortedHeads = getSavedOrderOfColumns(state.columnsRenderer(props.offers, usersSettings), tables, 'id')
    if (props.offers_filters) {
      if (props.offers_filters.group.length === 0) {
        return {
          usersSettings: usersSettings,
          heads: [{ ...state.defaultHeadColumn }, ...sortedHeads],
        }
      } else {
        let groupingHeadColumn = props.offers_filters.group.map((groupingKey, index, array) => {
          let customProperty = {}
          if (array.length - 1 === index) {
            customProperty.Expander = () => null
          }
          if (index === 0) {
            customProperty.Footer = <span>Total:</span>
          }
          if (groupingKey === 'hour_of_day') {
            customProperty.sortMethod = (a, b) => (a - b)
          }
          return ({
            title: capitalize(groupingKey),
            Header: () => <span>{capitalize(groupingKey)}</span>,
            accessor: groupingKey,
            minWidth: 170,
            width: 170,
            PivotValue: item => <span>{(item.value !== '') ? item.value : NA_VALUE}</span>,
            value: item => <span>{(item.value !== '') ? item.value : NA_VALUE}</span>,
            ...customProperty,

          })
        });
        return {
          usersSettings: usersSettings,
          heads: [...groupingHeadColumn, ...sortedHeads],
          groupingHeadColumn: [...groupingHeadColumn],
        }
      }
    }
    return null
  }

  componentDidMount() {
    const { scope, isChartVisible, defaultChartsReportsMetrics } = this.state
    const { offer_ids, users } = this.props
    const tables = users.settings && users.settings.settings ? users.settings.settings : []
    if (tables.length !== 0) {
      DASHBOARD_EXCLUDE_METRICS.map(metric => {
        const isNeedToExcludeElement = tables.find(el => el.id === metric)
        if (isNeedToExcludeElement) {
          if (!isNeedToExcludeElement.enabled) {
            if (defaultChartsReportsMetrics.findIndex(excludedMetric => excludedMetric === isNeedToExcludeElement.id) >= 0) {
              defaultChartsReportsMetrics.splice(defaultChartsReportsMetrics.findIndex(excludedMetric => excludedMetric === isNeedToExcludeElement.id), 1);
              defaultChartsReportsMetrics.push(getSavedOrderOfColumns(METRICS_DATA, tables, 'value')[0].value)
            }
          }
        }
      })
    }
    const filters = store.getState().reports.offers_filters
    if (isChartVisible) {
      store.dispatch(loadReportChartAction(scope, {  ...filters, offer_ids: offer_ids }))
      .then(chartData => {
        this.addChartData(chartData)
      })
    }
    this.setState({ defaultChartsReportsMetrics, defaultHeads: this._renderOffersColumns(this.props.offers, users.settings) })
  }

  _renderOffersColumns = (offers, user) => {
    return [
      {
        id: 'impressions',
        title: 'Impressions',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'impressions',
        Header: () => <span>Impressions</span>,
        Footer: t => sum((offers || []).map(r => r.impressions)),
        aggregate: values => sum(values),
      },
      {
        id: 'impressions_visible',
        title: 'Visible Impressions',
        className: '',
        accessor: 'impressions_visible',
        minWidth: 120,
        width: 120,
        Header: () => <span>Visible Impressions</span>,
        Footer: footer => sum((offers || []).map(row => row.impressions_visible)),
        aggregate: values => sum(values),
      },
      {
        id: 'clicks',
        title: 'Clicks',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'clicks',
        Header: () => <span>Clicks</span>,
        Footer: t => sum((offers || []).map(r => r.clicks)),
        aggregate: values => sum(values),
      },
      {
        id: 'unique_clicks',
        title: 'Unique Clicks',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'unique_clicks',
        Header: () => <span>Unique Clicks</span>,
        Footer: t => sum((offers || []).map(r => r.unique_clicks)),
        aggregate: values => sum(values),
      },
      {
        id: 'unique_clicks',
        title: 'Unique clicks',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'unique_clicks',
        Header: () => <span>Unique clicks</span>,
        Footer: t => sum((offers || []).map(r => r.unique_clicks)),
        aggregate: values => sum(values),
      },
      {
        id: 'ctr',
        title: 'CTR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'ctr',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>CTR</span>,
        aggregate: (values, rows) => {
          const impressions = sumHiddenColumn(rows, 'impressions')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return impressions !== 0 ? clicks / impressions : 0
        },
        Footer: t => {
          const impressions = sum((offers || []).map(r => r.impressions))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(impressions !== 0 ? clicks / impressions : 0).format('0.00%')
        },
      },
      {
        id: 'lp_views',
        title: 'LP Views',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'lp_views',
        description: 'Landing page views',
        Header: () => <span>LP Views</span>,
        Footer: t => sum((offers || []).map(resource => resource.lp_views)),
        aggregate: values => sum(values),
      },
      {
        id: 'prelp_views',
        title: 'PreLP Views',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'prelp_views',
        description: 'PreLP Views',
        Header: () => <span>PreLP Views</span>,
        Footer: footer => sum((offers || []).map(row => row.prelp_views)),
        aggregate: values => sum(values),
      },
      {
        id: 'lp_clicks',
        title: 'LP clicks',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'lp_clicks',
        description: 'Clicks from Lander to offer',
        Header: () => <span>LP Clicks</span>,
        Footer: t => sum((offers || []).map(resource => resource.lp_clicks)),
        aggregate: values => sum(values),
      },
      {
        id: 'prelp_clicks',
        title: 'Pre LP clicks',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'prelp_clicks',
        description: 'Pre LP clicks',
        Header: () => <span>Pre LP clicks</span>,
        Footer: footer => sum((offers || []).map(row => row.prelp_clicks)),
        aggregate: values => sum(values),
      },
      {
        id: 'prelp_click_ctr',
        title: 'Pre-LP click CTR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'prelp_click_ctr',
        description: 'Pre-LP click CTR',
        Cell: data => numeral((data.row.prelp_clicks && data.row.clicks !== 0) ? data.row.prelp_clicks/data.row.clicks : 0).format('0.00%'),
        Header: () => <span>Pre-LP click CTR</span>,
        Footer: t => {
          const prelp_clicks = sum((offers || []).map(r => r.prelp_clicks))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? prelp_clicks / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const prelp_clicks = sumHiddenColumn(rows, 'prelp_clicks')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? prelp_clicks / clicks : 0
        },
      },
      {
        id: 'lp_ctr',
        title: 'LP CTR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'lp_ctr',
        description: 'LP Clicks to LP views ratio',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>LP CTR</span>,
        Footer: t => {
          const lp_clicks = sum((offers || []).map(r => r.lp_clicks))
          const lp_views = sum((offers || []).map(r => r.lp_views))
          return numeral(lp_views !== 0 ? lp_clicks / lp_views : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const lp_clicks = sumHiddenColumn(rows, 'lp_clicks')
          const lp_views = sumHiddenColumn(rows, 'lp_views')
          return lp_views !== 0 ? lp_clicks / lp_views : 0
        },
      },
      {
        id: 'lp_views_cr',
        title: 'LP Views CR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'lp_views_cr',
        description: 'LP Views CR',
        Cell: data => numeral((data.row.conversions && data.row.lp_views !== 0) ? data.row.conversions/data.row.lp_views : 0).format('0.00%'),
        Header: () => <span>LP Views CR</span>,
        Footer: footer => {
          const conversions = sum((offers || []).map(row => row.conversions))
          const lp_views = sum((offers || []).map(row => row.lp_views))
          return numeral(lp_views !== 0 ? conversions / lp_views : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'conversions')
          const lp_views = sumHiddenColumn(rows, 'lp_views')
          return lp_views !== 0 ? conversions / lp_views : 0
        },
      },
      {
        id: 'lp_clicks_cr',
        title: 'LP Clicks CR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.lp_clicks_cr',
        description: 'LP Clicks CR',
        Cell: data => numeral((data.row.conversions && data.row.lp_clicks !== 0) ? data.row.conversions/data.row.lp_clicks : 0).format('0.00%'),
        Header: () => <span>LP Clicks CR</span>,
        Footer: footer => {
          const conversions = sum((offers || []).map(row => row.conversions))
          const lp_clicks = sum((offers || []).map(row => row.lp_clicks))
          return numeral(lp_clicks !== 0 ? conversions / lp_clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'conversions')
          const lp_clicks = sumHiddenColumn(rows, 'lp_clicks')
          return lp_clicks !== 0 ? conversions / lp_clicks : 0
        },
      },
      {
        id: 'lp_click_ctr',
        title: 'LP Click CTR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'stat.lp_click_ctr',
        description: 'Clicks to LP clicks Ratio',
        Cell: data => numeral((data.row.lp_clicks && data.row.clicks !== 0) ? data.row.lp_clicks/data.row.clicks : 0).format('0.00%'),
        Header: () => <span>LP Click CTR</span>,
        Footer: footer => {
          const lp_clicks = sum((offers || []).map(row => row.lp_clicks))
          const clicks    = sum((offers || []).map(row => row.clicks))
          return numeral(clicks !== 0 ? lp_clicks / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const clicks = sumHiddenColumn(rows, 'clicks')
          const lp_clicks = sumHiddenColumn(rows, 'lp_clicks')
          return clicks !== 0 ? lp_clicks / clicks : 0
        },
      },
      {
        id: 'conversions',
        title: renderConvTypeTitle('conv_default_type','conv_', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'conversions',
        Cell: row => (row.value || 0),
        Header: () => <span>{renderConvTypeTitle('conv_default_type','conv_', user)}</span>,
        Footer: t => sum((offers || []).map(r => r.conversions)),
        aggregate: values => sum(values),
      },
      {
        id: 'total_conversions',
        title: 'Total Actions',
        className: '',
        minWidth: 150,
        width: 150,
        accessor: 'total_conversions',
        Cell: row => (row.value || 0),
        Header: () => <span>Total Actions</span>,
        Footer: footer => sum((offers || []).map(row => row.total_conversions)),
        aggregate: values => sum(values),
      },
      {
        id: 'cr',
        title: 'CR',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'cr',
        description: 'Conversion rate (clicks to conversions)',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>CR</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.conversions))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'conversions')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'tr',
        title: 'TR',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'tr',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>TR</span>,
        Footer: t => {
          const transactions = sum((offers || []).map(r => r.transactions))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? transactions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const transactions = sumHiddenColumn(rows, 'transactions')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? transactions / clicks : 0
        },
      },
      {
        id: 'total_conversions_cr',
        title: 'Total Actions CR',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'total_conversions_cr',
        Cell: data => numeral(data.row.total_conversions && data.row.clicks !== 0 ? data.row.total_conversions / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>Total Actions CR</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.total_conversions))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'total_conversions')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'approved',
        title: 'Approved',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'approved',
        Header: () => <span>Approved</span>,
        Footer: t => sum((offers || []).map(r => r.approved)),
        aggregate: values => sum(values),
      },
      {
        id: 'approved_rate',
        title: 'Approved rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'approved_rate',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>Approved rate</span>,
        Footer: t => {
          const approved = sum((offers || []).map(r => r.approved))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? approved / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const approved = sumHiddenColumn(rows, 'approved')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? approved / clicks : 0
        },
      },
      {
        id: 'pending',
        title: 'Pending',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'pending',
        Header: () => <span>Pending</span>,
        Footer: t => sum((offers || []).map(r => r.pending)),
        aggregate: values => sum(values),
      },
      {
        id: 'pending_rate',
        title: 'Pending rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'pending_rate',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>Pending rate</span>,
        Footer: t => {
          const pending = sum((offers || []).map(r => r.pending))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? pending / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const pending = sumHiddenColumn(rows, 'pending')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? pending / clicks : 0
        },
      },
      {
        id: 'declined',
        title: 'Declined',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'declined',
        Header: () => <span>Declined</span>,
        Footer: t => sum((offers || []).map(r => r.declined)),
        aggregate: values => sum(values),
      },
      {
        id: 'declined_rate',
        title: 'Declined rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'declined_rate',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>Declined rate</span>,
        Footer: t => {
          const declined = sum((offers || []).map(r => r.declined))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? declined / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const declined = sumHiddenColumn(rows, 'declined')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? declined / clicks : 0
        },
      },
      {
        id: 'other',
        title: 'Other',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'other',
        Header: () => <span>Other</span>,
        Footer: t => sum((offers || []).map(r => r.other)),
        aggregate: values => sum(values),
      },
      {
        id: 'other_rate',
        title: 'Other rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'other_rate',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>Other rate</span>,
        Footer: t => {
          const other = sum((offers || []).map(r => r.other))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? other / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const other = sumHiddenColumn(rows, 'other')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? other / clicks : 0
        },
      },
      {
        id: 'transactions',
        title: 'Transactions',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'transactions',
        Header: () => <span>Transactions</span>,
        Footer: t => sum((offers || []).map(r => r.transactions)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype1',
        title: renderConvTypeTitle('convtype1','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype1',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype1','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype1)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype2',
        title: renderConvTypeTitle('convtype2','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype2',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype2','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype2)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype3',
        title: renderConvTypeTitle('convtype3','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype3',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype3','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype3)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype4',
        title: renderConvTypeTitle('convtype4','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype4',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype4','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype4)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype5',
        title: renderConvTypeTitle('convtype5','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype5',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype5','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype5)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype6',
        title: renderConvTypeTitle('convtype6','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype6',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype6','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype6)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype7',
        title: renderConvTypeTitle('convtype7','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype7',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype7','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype7)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype8',
        title: renderConvTypeTitle('convtype8','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype8',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype8','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype8)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype9',
        title: renderConvTypeTitle('convtype9','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype9',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype9','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype9)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype10',
        title: renderConvTypeTitle('convtype10','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype10',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype10','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype10)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype11',
        title: renderConvTypeTitle('convtype11','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype11',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype11','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype11)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype12',
        title: renderConvTypeTitle('convtype12','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype12',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype12','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype12)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype13',
        title: renderConvTypeTitle('convtype13','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype13',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype13','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype13)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype14',
        title: renderConvTypeTitle('convtype14','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype14',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype14','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype14)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype15',
        title: renderConvTypeTitle('convtype15','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype15',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype15','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype15)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype16',
        title: renderConvTypeTitle('convtype16','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype16',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype16','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype16)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype17',
        title: renderConvTypeTitle('convtype17','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype17',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype17','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype17)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype18',
        title: renderConvTypeTitle('convtype18','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype18',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype18','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype18)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype19',
        title: renderConvTypeTitle('convtype19','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype19',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype19','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype19)),
        aggregate: values => sum(values),
      },
      {
        id: 'convtype20',
        title: renderConvTypeTitle('convtype20','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convtype20',
        Header: () =>
          <span>
              {renderConvTypeTitle('convtype20','conv', user)}
            </span>,
        Footer: t => sum((offers || []).map(r => r.convtype20)),
        aggregate: values => sum(values),
      },
      {
        id: 'convratetype1',
        title: renderConvTypeRateTitle('convtype1','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype1',
        Cell: data => numeral(data.row.convtype1 && data.row.clicks !== 0 ? data.row.convtype1 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype1','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype1))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype1')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype2',
        title: renderConvTypeRateTitle('convtype2','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype2',
        Cell: data => numeral(data.row.convtype2 && data.row.clicks !== 0 ? data.row.convtype2 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype2','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype2))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype2')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype3',
        title: renderConvTypeRateTitle('convtype3','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype3',
        Cell: data => numeral(data.row.convtype3 && data.row.clicks !== 0 ? data.row.convtype3 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype3','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype3))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype3')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype4',
        title: renderConvTypeRateTitle('convtype4','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype4',
        Cell: data => numeral(data.row.convtype4 && data.row.clicks !== 0 ? data.row.convtype4 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype4','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype4))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype4')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype5',
        title: renderConvTypeRateTitle('convtype5','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype5',
        Cell: data => numeral(data.row.convtype5 && data.row.clicks !== 0 ? data.row.convtype5 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype5','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype5))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype5')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype6',
        title: renderConvTypeRateTitle('convtype6','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype6',
        Cell: data => numeral(data.row.convtype6 && data.row.clicks !== 0 ? data.row.convtype6 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype6','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype6))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype6')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype7',
        title: renderConvTypeRateTitle('convtype7','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype7',
        Cell: data => numeral(data.row.convtype7 && data.row.clicks !== 0 ? data.row.convtype7 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype7','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype7))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype7')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype8',
        title: renderConvTypeRateTitle('convtype8','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype8',
        Cell: data => numeral(data.row.convtype8 && data.row.clicks !== 0 ? data.row.convtype8 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype8','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype8))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype8')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype9',
        title: renderConvTypeRateTitle('convtype9','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype9',
        Cell: data => numeral(data.row.convtype9 && data.row.clicks !== 0 ? data.row.convtype9 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype9','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype9))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype9')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype10',
        title: renderConvTypeRateTitle('convtype10','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype10',
        Cell: data => numeral(data.row.convtype10 && data.row.clicks !== 0 ? data.row.convtype10 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype10','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype10))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype10')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype11',
        title: renderConvTypeRateTitle('convtype11','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype11',
        Cell: data => numeral(data.row.convtype11 && data.row.clicks !== 0 ? data.row.convtype11 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype11','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype11))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype11')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype12',
        title: renderConvTypeRateTitle('convtype12','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype12',
        Cell: data => numeral(data.row.convtype12 && data.row.clicks !== 0 ? data.row.convtype12 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype12','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype12))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype12')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype13',
        title: renderConvTypeRateTitle('convtype13','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype13',
        Cell: data => numeral(data.row.convtype13 && data.row.clicks !== 0 ? data.row.convtype13 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype13','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype13))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype13')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype14',
        title: renderConvTypeRateTitle('convtype14','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype14',
        Cell: data => numeral(data.row.convtype14 && data.row.clicks !== 0 ? data.row.convtype14 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype14','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype14))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype14')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype15',
        title: renderConvTypeRateTitle('convtype15','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype15',
        Cell: data => numeral(data.row.convtype15 && data.row.clicks !== 0 ? data.row.convtype15 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype15','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype15))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype15')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype16',
        title: renderConvTypeRateTitle('convtype16','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype16',
        Cell: data => numeral(data.row.convtype16 && data.row.clicks !== 0 ? data.row.convtype16 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype16','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype16))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype16')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype17',
        title: renderConvTypeRateTitle('convtype17','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype17',
        Cell: data => numeral(data.row.convtype17 && data.row.clicks !== 0 ? data.row.convtype17 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype17','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype17))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype17')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype18',
        title: renderConvTypeRateTitle('convtype18','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype18',
        Cell: data => numeral(data.row.convtype18 && data.row.clicks !== 0 ? data.row.convtype18 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype18','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype18))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype18')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype19',
        title: renderConvTypeRateTitle('convtype19','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype19',
        Cell: data => numeral(data.row.convtype19 && data.row.clicks !== 0 ? data.row.convtype19 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype19','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype19))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype19')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'convratetype20',
        title: renderConvTypeRateTitle('convtype20','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convratetype20',
        Cell: data => numeral(data.row.convtype20 && data.row.clicks !== 0 ? data.row.convtype20 / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>{renderConvTypeRateTitle('convtype20','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype20))
          const clicks = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype20')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'revenuetype1',
        title: renderRevenueTypeTitle('revenuetype1','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype1',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype1','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype1))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype2',
        title: renderRevenueTypeTitle('revenuetype2','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype2',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype2','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype2))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype3',
        title: renderRevenueTypeTitle('revenuetype3','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype3',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype3','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype3))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype4',
        title: renderRevenueTypeTitle('revenuetype4','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype4',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype4','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype4))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype5',
        title: renderRevenueTypeTitle('revenuetype5','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype5',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype5','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype5))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype6',
        title: renderRevenueTypeTitle('revenuetype6','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype6',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype6','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype6))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype7',
        title: renderRevenueTypeTitle('revenuetype7','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype7',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype7','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype7))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype8',
        title: renderRevenueTypeTitle('revenuetype8','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype8',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype8','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype8))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype9',
        title: renderRevenueTypeTitle('revenuetype9','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype9',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype9','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype9))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype10',
        title: renderRevenueTypeTitle('revenuetype10','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype10',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype10','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype10))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype11',
        title: renderRevenueTypeTitle('revenuetype11','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype11',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype11','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype11))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype12',
        title: renderRevenueTypeTitle('revenuetype12','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype12',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype12','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype12))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype13',
        title: renderRevenueTypeTitle('revenuetype13','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype13',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype13','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype13))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype14',
        title: renderRevenueTypeTitle('revenuetype14','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype14',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype14','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype14))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype15',
        title: renderRevenueTypeTitle('revenuetype15','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype15',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype15','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype15))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype16',
        title: renderRevenueTypeTitle('revenuetype16','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype16',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype16','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype16))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype17',
        title: renderRevenueTypeTitle('revenuetype17','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype17',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype17','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype17))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype18',
        title: renderRevenueTypeTitle('revenuetype18','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype18',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype18','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype18))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype19',
        title: renderRevenueTypeTitle('revenuetype19','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype19',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype19','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype19))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'revenuetype20',
        title: renderRevenueTypeTitle('revenuetype20','revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: 'revenuetype20',
        Header: () =>
          <span>
              {renderRevenueTypeTitle('revenuetype20','revenue', user)}
            </span>,
        Cell: row => numeral(row.value || 0).format('0.00[00]'),
        Footer: t => numeral(sum((offers || []).map(r => r.revenuetype20))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'convcpatype1',
        title: renderConvTypeCpaTitle('convtype1','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype1',
        Cell: data => numeral(data.row.cost && data.row.convtype1 !== 0 ? data.row.cost / data.row.convtype1 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype1','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype1))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype1')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype2',
        title: renderConvTypeCpaTitle('convtype2','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype2',
        Cell: data => numeral(data.row.cost && data.row.convtype2 !== 0 ? data.row.cost / data.row.convtype2 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype2','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype2))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype2')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype3',
        title: renderConvTypeCpaTitle('convtype3','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype3',
        Cell: data => numeral(data.row.cost && data.row.convtype3 !== 0 ? data.row.cost / data.row.convtype3 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype3','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype3))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype3')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype4',
        title: renderConvTypeCpaTitle('convtype4','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype4',
        Cell: data => numeral(data.row.cost && data.row.convtype4 !== 0 ? data.row.cost / data.row.convtype4 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype4','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype4))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype4')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype5',
        title: renderConvTypeCpaTitle('convtype5','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype5',
        Cell: data => numeral(data.row.cost && data.row.convtype5 !== 0 ? data.row.cost / data.row.convtype5 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype5','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype5))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype5')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype6',
        title: renderConvTypeCpaTitle('convtype6','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype6',
        Cell: data => numeral(data.row.cost && data.row.convtype6 !== 0 ? data.row.cost / data.row.convtype6 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype6','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype6))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype6')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype7',
        title: renderConvTypeCpaTitle('convtype7','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype7',
        Cell: data => numeral(data.row.cost && data.row.convtype7 !== 0 ? data.row.cost / data.row.convtype7 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype7','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype7))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype7')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype8',
        title: renderConvTypeCpaTitle('convtype8','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype8',
        Cell: data => numeral(data.row.cost && data.row.convtype8 !== 0 ? data.row.cost / data.row.convtype8 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype8','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype8))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype8')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype9',
        title: renderConvTypeCpaTitle('convtype9','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype9',
        Cell: data => numeral(data.row.cost && data.row.convtype9 !== 0 ? data.row.cost / data.row.convtype9 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype9','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype9))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype9')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype10',
        title: renderConvTypeCpaTitle('convtype10','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype10',
        Cell: data => numeral(data.row.cost && data.row.convtype10 !== 0 ? data.row.cost / data.row.convtype10 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype10','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype10))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype10')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype11',
        title: renderConvTypeCpaTitle('convtype11','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype11',
        Cell: data => numeral(data.row.cost && data.row.convtype11 !== 0 ? data.row.cost / data.row.convtype11 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype11','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype11))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype11')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype12',
        title: renderConvTypeCpaTitle('convtype12','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype12',
        Cell: data => numeral(data.row.cost && data.row.convtype12 !== 0 ? data.row.cost / data.row.convtype12 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype12','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype12))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype12')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype13',
        title: renderConvTypeCpaTitle('convtype13','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype13',
        Cell: data => numeral(data.row.cost && data.row.convtype13 !== 0 ? data.row.cost / data.row.convtype13 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype13','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype13))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype13')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype14',
        title: renderConvTypeCpaTitle('convtype14','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype14',
        Cell: data => numeral(data.row.cost && data.row.convtype14 !== 0 ? data.row.cost / data.row.convtype14 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype14','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype14))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype14')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype15',
        title: renderConvTypeCpaTitle('convtype15','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype15',
        Cell: data => numeral(data.row.cost && data.row.convtype15 !== 0 ? data.row.cost / data.row.convtype15 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype15','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype15))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype15')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype16',
        title: renderConvTypeCpaTitle('convtype16','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype16',
        Cell: data => numeral(data.row.cost && data.row.convtype16 !== 0 ? data.row.cost / data.row.convtype16 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype16','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype16))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype16')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype17',
        title: renderConvTypeCpaTitle('convtype17','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype17',
        Cell: data => numeral(data.row.cost && data.row.convtype17 !== 0 ? data.row.cost / data.row.convtype17 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype17','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype17))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype17')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype18',
        title: renderConvTypeCpaTitle('convtype18','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype18',
        Cell: data => numeral(data.row.cost && data.row.convtype18 !== 0 ? data.row.cost / data.row.convtype18 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype18','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype18))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype18')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype19',
        title: renderConvTypeCpaTitle('convtype19','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype19',
        Cell: data => numeral(data.row.cost && data.row.convtype19 !== 0 ? data.row.cost / data.row.convtype19 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype19','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype19))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype19')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      {
        id: 'convcpatype20',
        title: renderConvTypeCpaTitle('convtype20','conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'convcpatype20',
        Cell: data => numeral(data.row.cost && data.row.convtype20 !== 0 ? data.row.cost / data.row.convtype20 : 0).format('0.00[00]'),
        Header: () => <span>{renderConvTypeCpaTitle('convtype20','conv', user)}</span>,
        Footer: t => {
          const conversions = sum((offers || []).map(r => r.convtype20))
          const cost = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'convtype20')
          const cost = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
      },
      ...renderConvTypeAov(user, offers, '', CONVTYPE_COUNT),
      {
        id: 'revenue',
        title: renderRevenueTypeTitle('revenue_default_type','revenue_', user),
        className: 'bolder',
        minWidth: 180,
        width: 180,
        accessor: 'revenue',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>{renderRevenueTypeTitle('revenue_default_type','revenue_', user)}</span>,
        Footer: t => numeral(sum((offers || []).map(r => r.revenue))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'total_revenue',
        title: 'Total Revenue',
        className: 'bolder',
        minWidth: 150,
        width: 150,
        accessor: 'total_revenue',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>Total Revenue</span>,
        Footer: footer => numeral(sum((offers || []).map(row => row.total_revenue))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'cost',
        title: 'Cost',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'cost',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>Cost</span>,
        Footer: t => numeral(sum((offers || []).map(r => r.cost))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      // {
      //   id: 'pubrevenue',
      //   title: 'Comission',
      //   className: '',
      //   minWidth: 120,
      //   width: 120,
      //   accessor: 'pubrevenue',
      //   Cell: row => numeral(row.value).format('$0.00[00]'),
      //   Header: () => <span>Comission</span>,
      //   Footer: footer => numeral(sum((offers || []).map(row => row.pubrevenue))).format('$0.00[00]'),
      //   aggregate: values => sum(values),
      // },
      {
        id: 'profit',
        title: 'Profit',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'profit',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>Profit</span>,
        Footer: t => numeral(sum((offers || []).map(r => r.profit))).format('$0.00[00]'),
        aggregate: values => sum(values),
      },
      {
        id: 'roi',
        title: 'ROI',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'roi',
        description: 'Return on Investment',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>ROI</span>,
        aggregate: (values, rows) => {
          const profit = sumHiddenColumn(rows, 'profit')
          const cost   = sumHiddenColumn(rows, 'cost')
          return cost !== 0 ? profit / cost : 0
        },
        Footer: (t) => {
          const profit = sum((offers || []).map(r => r.profit))
          const cost   = sum((offers || []).map(r => r.cost))
          return numeral(cost !== 0 ? profit / cost : 0).format('0.00%')
        },
      },
      {
        id: 'cpa',
        title: 'CPA',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'cpa',
        description: 'Cost per Action',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>CPA</span>,
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'conversions')
          const cost   = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
        Footer: (t) => {
          const conversions = sum((offers || []).map(r => r.conversions))
          const cost   = sum((offers || []).map(r => r.cost))
          return numeral(conversions !== 0 ? cost / conversions : 0).format('$0.00[00]')
        },
      },
      {
        id: 'cpc',
        title: 'CPC',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'cpt',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        description: 'Cost per Click',
        Header: () => <span>CPC</span>,
        aggregate: (values, rows) => {
          const clicks = sumHiddenColumn(rows, 'clicks')
          const cost = sumHiddenColumn(rows, 'cost')
          return clicks !== 0 ? cost / clicks : 0
        },
        Footer: (t) => {
          const clicks = sum((offers || []).map(r => r.clicks))
          const cost   = sum((offers || []).map(r => r.cost))
          return numeral(clicks !== 0 ? cost / clicks : 0).format('$0.00[00]')
        },
      },
      {
        id: 'cpt',
        title: 'CPT',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'cpt',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        description: 'Cost per Transaction',
        Header: () => <span>CPT</span>,
        aggregate: (values, rows) => {
          const transactions = sumHiddenColumn(rows, 'transactions')
          const cost = sumHiddenColumn(rows, 'cost')
          return transactions !== 0 ? cost / transactions : 0
        },
        Footer: (t) => {
          const transactions = sum((offers || []).map(r => r.transactions))
          const cost   = sum((offers || []).map(r => r.cost))
          return numeral(transactions !== 0 ? cost / transactions : 0).format('$0.00[00]')
        },
      },
      {
        id: 'epc',
        title: 'EPC',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'epc',
        description: 'Earning per click',
        Cell: row => numeral(row.value).format('$0.00[00]'),
        Header: () => <span>EPC</span>,
        aggregate: (values, rows) => {
          const revenue = sumHiddenColumn(rows, 'revenue')
          const clicks   = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? revenue / clicks : 0
        },
        Footer: (t) => {
          const revenue = sum((offers || []).map(r => r.revenue))
          const clicks   = sum((offers || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? revenue / clicks : 0).format('$0.00[00]')
        },
      },
    ]
  }

  showChart = () => {
    const { scope, isChartVisible } = this.state
    const { offer_ids } = this.props
    this.reportsCharts.showChart()
    const filters = store.getState().reports.offers_filters
    if (!isChartVisible) {
      store.dispatch(loadReportChartAction(scope, {  ...filters, offer_ids: offer_ids }))
      .then(chartData => {
        this.addChartData(chartData)
      })
    }
    this.setState({
      isChartVisible: !isChartVisible,
    })
  };

  addChartData = (chartData) => {
    this.setState({
      reportData: chartData,
    })
  };

  renderRowColor = (row) => {
    let rowColor = '';
    const index  = row.index|| row.viewIndex
    if (index % 2 === 0) {
      rowColor = 'custom-odd'
    } else {
      rowColor = 'custom-even'
    }
    return rowColor
  };

  getTrProps = (state, item, column, instance) => {
    return {
      className: (item && item.subRows ? this.renderRowColor(item, item.row.roi) : 'hidden'),
    }
  }


  render () {
    const { heads, isChartVisible, isChartNeed, defaultChartsReportsMetrics, reportData } = this.state;
    const { offers, scope, offer_ids, offers_filters, loading, loaded } = this.props;
    let groupBy = offers_filters.group
    if (groupBy.length === 0) {
      groupBy = DEFAULT_GROUPING_BY_DATE
    }

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <OfferFilters
              offer_ids={offer_ids}
              scope={scope}
              offersFilters={offers_filters}
              isChartControlPresent={isChartNeed}
              showChart={this.showChart}
              addChartData={this.addChartData}
              isChartVisible={isChartVisible}
            />
            <LoadableContainer
              loading={loading}
              loaded={loaded}
              style={{
                position: 'relative',
                width: '100%',
                maxHeight: '350px'
              }}
            >
              {offers &&
              <Fragment>
                <ReportsCharts
                  scope={scope}
                  isChartVisible={isChartVisible}
                  optionsFilters={store.getState().reports[`${scope}_filters`]}
                  reportData={reportData}
                  chartItems={defaultChartsReportsMetrics}
                  onRef={ref => (this.reportsCharts = ref)}
                />
                <CustomizableTable
                  className='custom-data-offer-table'
                  columns={heads}
                  data={offers}
                  pivotBy={groupBy}
                  style={{ maxHeight: "100%", borderLeft: 0, borderRight: 0, borderBottom: 0, width: '100%' }}
                  showPagination={false}
                  getTrProps={this.getTrProps}
                  pageSize={offers.length}
                  loading={loading}
                />
              </Fragment>
              }
            </LoadableContainer>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

OfferReportTable.defaultProps = {
  scope: 'offers',
}

const mapStateToProps = (state) => ({
  users: state.users,
  offers: state.reports.offers,
  loading: state.reports.offers_loading,
  loaded: state.reports.offers_loaded,
  offers_filters: state.reports.offers_filters,
});

export default connect(
  mapStateToProps,
)(OfferReportTable)
