/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import {change, Field, reduxForm} from 'redux-form';
import moment from 'moment'
import { Col } from 'reactstrap';

import store from '../../../containers/App/store'
import { filterAction } from '../../../redux/actions/offersActions'
import renderIntervalDatePickerField from '../../../common/DatePicker'

class OffersFilters extends Component {
  constructor (props) {
    super(props);
    this.state = {
      filters: { ...store.getState().offers.filters },
      title: store.getState().offers.filters ? store.getState().offers.filters.title : '',
    }
  }

  componentDidMount() {
    const { title } = this.state
    this.props.dispatch(change('offer_filters_form', 'title', title));
  }

  componentWillUnmount() {
    const { filters } = this.state;
    filters.title = ''
    store.dispatch(filterAction(filters))
  }

  handleSubmit = () => {
    const { filters } = this.state;
    const { handleApplyFilters } = this.props
    store.dispatch(filterAction(filters))
    handleApplyFilters()
  };

  handleChange = (key, value) => {
    this.setState({ filters: { ...this.state.filters, [key]: value } })
  }

  handleChangeDate = (event) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        date_from: moment(event.start),
        date_to: moment(event.end),
      }
    })
  };

  handleChangeTitle = (event) => {
    const searchText = event.target.value
    this.handleChange(event.target.name, searchText)
  }

  render () {
    const { filters, title } = this.state;

    return (
      <Fragment>
        <form className="form form--horizontal" onSubmit={this.handleSubmit}>
          <Col md={4} lg={4} sm={4} className="no-padding-left">
            <div
              className="form__form-group datepicker_custom"
            >
              <span className="form__form-group-label">Period</span>
              <div className="form__form-group-field">
                <Field
                  name="offers"
                  component={renderIntervalDatePickerField}
                  onChange={this.handleChangeDate}
                  filters={filters}
                />
              </div>
            </div>
          </Col>

          <Col md={3} lg={3} sm={3}>
            <div className="form__form-group-field title-field-container no-padding-left">
              <Field
                name="title"
                component="input"
                type={'text'}
                value={title}
                placeholder="Title"
                onChange={this.handleChangeTitle}
              />
            </div>
          </Col>

          <Col md={2}>
            <div className='button-container'>
              <div
                onClick={this.handleSubmit}
                className="btn btn-primary account__btn"
              >
                Apply/Refresh
              </div>
            </div>
          </Col>
        </form>
      </Fragment>
    );
  }
}

OffersFilters.defaultProps = {
  handleApplyFilters: () => {}
}

const mapStateToProps = (state) => ({
})

export default reduxForm({
  form: 'offer_filters_form',
})(connect(mapStateToProps)(OffersFilters))
