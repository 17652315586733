import {
    signin,
    signup,
    updateUser,
    signout,
    getUserData,
    loadSettings,
	getUnregisteredUserData,
    resetPassword,
} from '../../utils/apiAdapter'
import noty from '../../utils/noty'
import { updateSessionObject, removeSessionItem } from '../../utils/index'
import { SESSION_OBJECT_KEY } from '../../constants'
import  {addOffersAction } from "./offersActions";

export const SIGN_IN = 'SIGN_IN';
export const SET_CURRENT_USER  = 'SET_CURRENT_USER';
export const SET_SETTINGS = 'SET_SETTINGS'
export const SIGN_OUT          = 'SIGN_OUT'
export const ERROR_OCCURED = 'ERROR_OCCURED'



export const signInAction = (credentials) => (
    (dispatch) => (
        signin(credentials)
        .then(user => {
            dispatch(setCurrentUserAction(user.data))
        })
    )
)

export const loadSettingsAction = (credentials) => (
  (dispatch) => {
    const sessionObject = JSON.parse(sessionStorage.getItem(SESSION_OBJECT_KEY));
    const expires = new Date();
    if (sessionObject) {
      if (!window.onbeforeunload) {
        window.onbeforeunload = removeSessionItem
      }
      if (expires.getTime() > sessionObject.expiresAt) {
        updateSessionObject(sessionObject, expires)
        // return loadSettings(credentials, (data) => dispatch(setSettingsAction(data)))
      }
    } else {
      updateSessionObject(sessionObject, expires)
    }
    return loadSettings(credentials, (data) => {
        return dispatch(setSettingsAction(data))
    })

  }
)

export const signUpAction = (credentials) => (
    (dispatch) => (
        signup(credentials)
            .then(user => {

                dispatch(setCurrentUserAction(user.data))
                return user
            })
    )
)

export const resetPasswordAction = email =>
    dispatch => (
        resetPassword(email).then(response => {
            if (!response.status == 204) {
                return response.json().then(err => {throw err})
            }
        })
        .then(() => noty({ text: 'Your password has been reset successfully', type: 'success' }))
    )

export const getUserDataAction = () => (
	(dispatch) => (
		getUserData()
			.then(user => {
				dispatch(setCurrentUserAction(user.data))
			})
	)
)

export const getUserDataForBecomeAction = data =>
	dispatch => (
		getUnregisteredUserData(data).then(response => {
			if (!response.status == 204) {
				return response.json().then(err => {throw err})
			}
			return response.data
		})

	)

export const updateUserAction = (credentials) => (
    (dispatch) => (
        updateUser(credentials)
            .then(user => {
                dispatch(setCurrentUserAction(user.data))
                dispatch(userUpdatedAction(user.data))
                return user
            })
    )
)

export const signOutAction = (dispatch) => {
    dispatch({type: SIGN_OUT});// TODO check if user is actually signed in
    window.location.href = '/'
}

export const errorOccuredAction = (message) =>
    ({ type: ERROR_OCCURED, data: message })

export const setCurrentUserAction = (user) => {
    return { type: SET_CURRENT_USER, data: user }
}

export const setSettingsAction = (settings) => {
    return { type: SET_SETTINGS, data: settings }
}

export const userUpdatedAction = (data) => (
    (dispatch) => {
        noty({ text: 'Your Postback URL has been saved successfully', type: 'success' })
        return data
    }
)
