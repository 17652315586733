import React, { Fragment, Component } from 'react'
import { Col, Row } from 'reactstrap'

import OfferTrackUrlPart from './OfferDetailsParts/OfferTrackUrlPart'
import OfferReportPart from './OfferDetailsParts/OfferReportPart'

class OfferDetailsRightPart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { resource } = this.props;

    return (
      <Col md={6} lg={6} sm={6}>
        <Row noGutters={true}>
          <OfferTrackUrlPart
            resource={resource}
          />
        </Row>
        <Row noGutters={true}>
          <OfferReportPart
            resource={resource}
          />
        </Row>
      </Col>
    )
  }

}

export default OfferDetailsRightPart
