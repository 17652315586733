import React, { Component } from 'react';
import _ from 'lodash'

import Metrics from './Metrics'
import ChartContainer from './ChartContainer'
import { filterByCurrentColumnsList, getSavedOrderOfColumns, renderMetricTitle } from '../../../../utils';

import { METRICS_DATA, DASHBOARD_METRICS_LIST, DASHBOARD_EXCLUDE_METRICS } from '../../../../constants/dashboard'
import { DASHBOARD_BY_DAY, DASHBOARD_BY_OFFER } from '../../../../constants'
import { FINANCIAL_HEADS_OFFERS, NON_FINANCIAL_HEADS_OFFERS } from "../../../../constants/offerstTable";
import { FINANCIAL_HEADS_DATE, NON_FINANCIAL_HEADS_DATE } from "../../../../constants/dateTable";

class Graphs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      metrics: {
        metrics_A: {
          options: METRICS_DATA,
          value: '',
          label: '',
          index: null,
          lineChartProperties: {},
        },
        metrics_B: {
          options: METRICS_DATA,
          value: '',
          label: '',
          index: null,
          lineChartProperties: {},
        },
        metrics_C: {
          options: METRICS_DATA,
          value: '',
          label: '',
          index: null,
          lineChartProperties: {},
        },
        metrics_D: {
          options: METRICS_DATA,
          value: '',
          label: '',
          index: null,
          lineChartProperties: {},
        },
        lineCharts: [],
      },
      customMetricsData: METRICS_DATA,
    }
  }


  componentDidMount() {
    const { customMetricsData, metrics } = this.state
    const { currentTable, users } = this.props
    let metricData = customMetricsData
    if (_.has(users, 'currentUser.settings.dashboard')) {
      if (users.currentUser.settings.dashboard.metrics) {
        const settingsMetrics = users.currentUser.settings.dashboard.metrics
        DASHBOARD_METRICS_LIST.map((metric, i) => {
          this.disableSelectedMetrics({
            value: settingsMetrics[metric].value,
            label: settingsMetrics[metric].label,
            currentMetric: metric,
            otherMetrics: DASHBOARD_METRICS_LIST.filter(el => el !== metric),
            lineChartProperties: { ...metric.lineChartProperties },
          }, () => null)
        })
      }
    } else {
      const usersSettings = users.settings || {};
      const tables = usersSettings && usersSettings.settings ? usersSettings.settings : []
      if (tables.length !== 0) {
        metricData = getSavedOrderOfColumns(metricData, tables, 'value')
        let currentOptionsList = _.cloneDeep(metricData)
        let columnsListData = []
        if (currentTable === DASHBOARD_BY_DAY) {
          columnsListData = [...(users && users.currentUser && users.currentUser.show_financial_data ? FINANCIAL_HEADS_DATE(usersSettings) : NON_FINANCIAL_HEADS_DATE(usersSettings))]
        } else if (currentTable === DASHBOARD_BY_OFFER) {
          columnsListData = [...(users && users.currentUser && users.currentUser.show_financial_data ? FINANCIAL_HEADS_OFFERS(usersSettings) : NON_FINANCIAL_HEADS_OFFERS(usersSettings))]
        }
        metricData = filterByCurrentColumnsList(currentOptionsList, getSavedOrderOfColumns(columnsListData, tables, 'key'), 'key')
        DASHBOARD_EXCLUDE_METRICS.map(metric => {
          const isNeedToExcludeElement = tables.find(el => el.id === metric)
          if (isNeedToExcludeElement) {
            if (!isNeedToExcludeElement.enabled) {
              metricData = metricData.filter(el => el.value !== isNeedToExcludeElement.id)
            }
          }
        })
      }
      metricData.map(option => option.label = renderMetricTitle(option.value, option.label, usersSettings))
      DASHBOARD_METRICS_LIST.map(metric => metrics[metric].options = metricData)
      this.setState({ customMetricsData: metricData }, () => {
        this.disableSelectedMetrics({
          value: metricData[0] && metricData[0].value ? metricData[0].value : null,
          label: metricData[0] && metricData[0].label ? metricData[0].label : '',
          options: metricData,
          currentMetric: 'metrics_A',
          otherMetrics: DASHBOARD_METRICS_LIST.filter(metric => metric !== 'metrics_A'),
        }, null)
        this.disableSelectedMetrics({
          value: metricData[1] && metricData[1].value ? metricData[1].value : null,
          label: metricData[1] && metricData[1].label ? metricData[1].label : '',
          options: metricData,
          currentMetric: 'metrics_B',
          otherMetrics: DASHBOARD_METRICS_LIST.filter(metric => metric !== 'metrics_B'),
        }, null)
        this.disableSelectedMetrics({
          value: metricData[2] && metricData[2].value ? metricData[2].value : null,
          label: metricData[2] && metricData[2].label ? metricData[2].label : '',
          options: metricData,
          currentMetric: 'metrics_C',
          otherMetrics: DASHBOARD_METRICS_LIST.filter(metric => metric !== 'metrics_C'),
        }, null)
        this.disableSelectedMetrics({
          value: metricData[3] && metricData[3].value ? metricData[3].value : null,
          label: metricData[3] && metricData[3].label ? metricData[3].label : '',
          options: metricData,
          currentMetric: 'metrics_D',
          otherMetrics: DASHBOARD_METRICS_LIST.filter(metric => metric !== 'metrics_D'),
        }, null)
      })
    }
  }

  componentWillUnmount() {
    METRICS_DATA.map((el,i) => el.disabled = false)
  }

  prepareSortedReportColumns = (metrics) => {
    const metricsReportsColumns = []
    let metricsKey = Object.keys(metrics)
    metricsKey.splice(-1,1)
    metricsKey.map((el, i) => {
      if (metrics[el].value) {
        metricsReportsColumns.push(metrics[el].value)
      }
    })
    return metricsReportsColumns
  }

  disableSelectedMetrics = ({ value, label, options, currentMetric, otherMetrics }, saveMetrics) => {
    const { metrics } = this.state
    const { renderReportColumns } = this.props
    metrics.lineCharts = []
    const metrics_currentMetric = metrics[currentMetric]
    const lineChartData = METRICS_DATA.find((el) => el.value === value)
    metrics_currentMetric.value = value
    metrics_currentMetric.label = label
    metrics_currentMetric.options = options
    metrics_currentMetric.lineChartProperties = { ...(lineChartData ? lineChartData.lineChartProperties : lineChartData) }
    if (metrics_currentMetric.index !== null) {
      otherMetrics.map((el) => {
        metrics[el].options = options
        metrics[el].options[metrics_currentMetric.index].disabled = false
      })
    }
    const index = metrics_currentMetric.options.findIndex((el) => el.value === value)
    metrics_currentMetric.index = (value === null || index < 0) ? null : index
    if(value) {
      otherMetrics.map((el) => {
        metrics[el].options[metrics_currentMetric.index].disabled = true
      })
    }

    const metricsReportsColumns = this.prepareSortedReportColumns(metrics)
    metrics_currentMetric.options.map((el, i) => {
      if (el.disabled) {
        metrics.lineCharts.push(el)
      }
    })
    metrics.lineCharts.sort((a, b) => metricsReportsColumns.indexOf(a.value) - metricsReportsColumns.indexOf(b.value))
    this.setState({ metrics }, () => {
      renderReportColumns(this.state.metrics.lineCharts)
      // saveMetrics(this.state.metrics)
    })
  }

  handleChangeMetrics_A = (option) => {
    const { customMetricsData } = this.state
    this.disableSelectedMetrics({
      value: option ? option.value : option,
      label: option ? option.label : '',
      options: customMetricsData,
      currentMetric: 'metrics_A',
      otherMetrics: ['metrics_B','metrics_C','metrics_D'],
    }, null)
  }

  handleChangeMetrics_B = (option) => {
    const { customMetricsData } = this.state
    this.disableSelectedMetrics({
      value: option ? option.value : option,
      label: option ? option.label : '',
      options: customMetricsData,
      currentMetric: 'metrics_B',
      otherMetrics: ['metrics_A','metrics_C','metrics_D'],
    }, null)
  }

  handleChangeMetrics_C = (option) => {
    const { customMetricsData } = this.state
    this.disableSelectedMetrics({
      value: option ? option.value : option,
      label: option ? option.label : '',
      options: customMetricsData,
      currentMetric: 'metrics_C',
      otherMetrics: ['metrics_A','metrics_B','metrics_D'],
    }, null)
  }

  handleChangeMetrics_D = (option) => {
    const { customMetricsData } = this.state
    this.disableSelectedMetrics({
      value: option ? option.value : option,
      label: option ? option.label : '',
      options: customMetricsData,
      currentMetric: 'metrics_D',
      otherMetrics: ['metrics_A','metrics_B','metrics_C'],
    }, null)
  }

  render() {
    const { metrics } = this.state
    const { stat, scope } = this.props
    return (
      <div className="form pl-0 pt-0">
        <Metrics
          metrics={metrics}
          stat={stat}
          handleChangeMetrics_A={this.handleChangeMetrics_A}
          handleChangeMetrics_B={this.handleChangeMetrics_B}
          handleChangeMetrics_C={this.handleChangeMetrics_C}
          handleChangeMetrics_D={this.handleChangeMetrics_D}
        />
        <div className="form__form-group">
          <div className="form__form-group-field">
            <ChartContainer
              scope={scope}
              metrics={metrics}
              stat={stat}
            />
          </div>
        </div>
      </div>
    )
  }

}

Graphs.defaultProps = {
  users: {},
}

export default Graphs
