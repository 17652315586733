import React from 'react';
import { render } from 'react-dom';
import App from './containers/App/App';
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: "https://22320578935c42289a91750c8eca2cc5@sentry.io/1865083",
		sampleRate: 0.1,
		blacklistUrls: ['https://track.gogocpa.app/', '\\bgogocpa\\b']
	})
}

render(
  <App />,
  document.getElementById('root'),
);
