/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react';
import moment from 'moment'
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import DataPaginationTable from '../../shared/components/table/DataPaginationTable';
import store from "../App/store";
import { connect } from 'react-redux';
import {loadPaymentsAction} from "../../redux/actions/paymentsActions";

const DateFormatter = ({ value }) => {
    return moment(value).format('YYYY-DD-MM hh:mm')
}

const AmountFormatter = ({ value }) => {
    return value+' $'
}

class PaymentHistory extends Component {
    constructor(props) {
        super(props);
        this.heads = [
            {
                key: 'created_at',
                name: 'Date',
                sortable: false,
                formatter: DateFormatter
            },
            {
                key: 'amount',
                name: 'Amount',
                sortable: false,
                formatter: AmountFormatter
            },

            {
                key: 'note',
                name: 'Notes',
                sortable: false
            },
        ];

    }

    componentDidMount() {
        this.serverRequest = store.dispatch(loadPaymentsAction)
    }

    render() {
        let items = []
        items = this.props.payments.items


        return (
            <Container>
                <Row>
                    <Col md={12}>
                        <h3 className="page-title">Payment History</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                                {items &&
                                    <DataPaginationTable
                                        heads={this.heads}
                                        rows={items}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    payments: state.payments,
})

export default connect(
    mapStateToProps,
)(PaymentHistory)
