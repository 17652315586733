/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import MinusIcon from 'mdi-react/MinusIcon';
import store from '../containers/App/store'
import moment from 'moment'

class IntervalDatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    filters: PropTypes.object,
  };

  constructor (props) {
    super(props);
    this.state = {
      startDate: moment(store.getState().reports[`${this.props.name}_filters`].date_from).toDate(),
      endDate: moment(store.getState().reports[`${this.props.name}_filters`].date_to).toDate(),
    };
  }

  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;

    if (moment(startDate).isAfter(endDate)) {
      endDate = startDate;
    }

    this.setState({ startDate, endDate });
    this.props.onChange({ start: startDate, end: endDate });
  }

  handleChangeStart = startDate => this.handleChange({ startDate });

  handleChangeEnd = endDate => this.handleChange({ endDate });

  render () {
    return (
      <div className="date-picker date-picker--interval">
        <DatePicker
          selected={this.state.startDate}
          selectsStart
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={this.handleChangeStart}
          dateFormat="yyyy-MM-dd"
          placeholderText="From"
        />
        <MinusIcon className="date-picker__svg"/>
        <DatePicker
          selected={this.state.endDate}
          selectsEnd
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={this.handleChangeEnd}
          dateFormat="yyyy-MM-dd"
          placeholderText="To"
        />
      </div>
    );
  }
}

const renderIntervalDatePickerField = props => (
  <IntervalDatePickerField
    {...props.input}
  />
);

renderIntervalDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
};

export default renderIntervalDatePickerField;
