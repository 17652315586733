/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form';
import moment from 'moment'
import { Button, ButtonToolbar, Col } from 'reactstrap';
import Select from 'react-select';
import Switch from "react-switch";

import store from '../../../../containers/App/store'
import { loadReportAction, loadReportChartAction } from '../../../../redux/actions/reportActions'
import renderIntervalDatePickerField from '../../../../common/DatePicker'
import FancyGroupingPanel from '../../../../common/FancyGroupingPanel'
import { capitalize } from '../../../../utils/Helpers'

import { MAX_GROUPING_VALUES } from '../../../../constants';
import { BASE_FANCY_GROUP_OPTIONS, BASE_GROUPING_OPTIONS } from '../../../../constants/filters';

class OfferFilters extends Component {
  constructor (props) {
    super(props);
    this.state = {
      filters: store.getState().reports.offers_filters,
      scope: 'offers',
      baseOptions: BASE_GROUPING_OPTIONS.map(opt =>
        ({ label: capitalize(opt), value: opt, isDisabled: false })
      ),
    }
  }

  handleSubmit = () => {
    const { offer_ids, isChartVisible, addChartData } = this.props;
    const { filters, scope } = this.state;
    if (isChartVisible) {
      store.dispatch(loadReportChartAction(scope, {  ...filters, offer_ids: offer_ids }))
      .then(chartData => {
        if (addChartData) {
          addChartData(chartData)
        }
      })
    }
    store.dispatch(loadReportAction(scope, {  ...filters, offer_ids: offer_ids }))
  };

  applyGrouping = (option) => {
    const { filters, baseOptions } = this.state;
    let newBaseOptions = baseOptions.map(el => ({ ...el, isDisabled: false }));
    this.setState({
      filters:
        {
          ...filters,
          group: [option]
        },
      baseOptions: [...newBaseOptions],
    }, () => this.handleSubmit())
  };

  handleChange = (event) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        date_from: moment(event.start),
        date_to: moment(event.end),
      }
    })
  };

  handleChangeSelect = (event) => {
    const { filters, baseOptions } = this.state;
    let newBaseOptions = [...baseOptions],
      groupingOptions = [];
    if (event) {
      if (event.length <= MAX_GROUPING_VALUES) {
        groupingOptions = event.map((element, i) => element.value);
      }
      newBaseOptions = baseOptions.map(el => ({ ...el, isDisabled: event.length === MAX_GROUPING_VALUES }));
    }

    this.setState({
      filters: {
        ...filters,
        group: groupingOptions,
      },
      baseOptions: newBaseOptions,
    });
  };

  render () {
    const { filters, baseOptions } = this.state;
    const { offersFilters, isChartControlPresent, showChart, isChartVisible, scope } = this.props;
    const multiSelectValue = [];
    filters.group.map((groupOption) => {
      baseOptions.map((option) => {
        if (option.value === groupOption) {
          multiSelectValue.push(option)
        }
      })
    });
    const label = isChartVisible ? `Hide charts for ${scope} report` : `Show charts for ${scope} report`;
    return (
      <React.Fragment>
        <FancyGroupingPanel
          items={BASE_FANCY_GROUP_OPTIONS}
          active={filters.group[0]}
          onSelect={this.applyGrouping}
        />
        <form className="form form--horizontal" onSubmit={this.handleSubmit}>
          <Col md={4} lg={4} sm={4} className="no-padding-left">
            <div
              className="form__form-group datepicker_custom"
            >
              <span className="form__form-group-label">Period</span>
              <div className="form__form-group-field">
                <Field
                  name="offers"
                  component={renderIntervalDatePickerField}
                  onChange={this.handleChange}
                  filters={filters}
                />
              </div>
            </div>
          </Col>

          <Col md={4} lg={4} sm={4}>
            <div
              className="form__form-group"
            >
              <span className="form__form-group-label">Group by</span>
              <div className="form__form-group-field">
                <Select
                  style={{
                    minWidth: '100%',
                  }}
                  isMulti
                  name='select'
                  value={multiSelectValue}
                  defaultValue={baseOptions.find(option => option.value === offersFilters.group[0])}
                  onChange={this.handleChangeSelect}
                  options={baseOptions}
                  clearable={false}
                  closeOnSelect={false}
                  removeSelected={false}
                  className="react-select"
                  placeholder='Choose grouping'
                  classNamePrefix="react-select"
                />
              </div>
            </div>
          </Col>

          {isChartControlPresent && <Col md={2} lg={2} sm={2}>
            <div className="form__form-group custom-label-switch">
              <span className="form__form-group-label">{label}</span>
              <div className="form__form-group-field">
                <Switch
                  onChange={showChart}
                  checked={isChartVisible}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={38}
                  onColor="#64bd63"
                  offColor="#cecece"
                  className="react-switch"
                />
              </div>
            </div>
          </Col>}
          <ButtonToolbar
            className="form__button-toolbar"
            style={{
              marginBottom: 0,
              marginTop: 0,
              marginLeft: 0,
            }}
          >
            <Button
              color="primary"
              onClick={this.handleSubmit}
              style={{
                height: 42,
                lineHeight: '10px',
              }}
            >
              Apply
            </Button>
          </ButtonToolbar>
        </form>
      </React.Fragment>
    );
  }
}

OfferFilters.defaultProps = {
  isChartControlPresent: false,
  isChartVisible: false,
  scope: 'offers',
  showChart: () => {},
  addChartData: () => {},
}

const mapStateToProps = (state) => ({
})

export default reduxForm({
  form: 'horizontal_form',
})(connect(mapStateToProps)(OfferFilters))
